import React, { useState, useEffect, useRef } from 'react';
import ActivityCard from '../components/ActivityCard';
//import ActivitySearchBox from '../components/ActivitySearchBox';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import IntroChillful from '../components/IntroChillful';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
//import img_article_1 from '../images/articles/article1.jpg';
//import img_article_2 from '../images/articles/article2.jpg';
//import img_article_3 from '../images/articles/article3.jpg';
//import img_article_4 from '../images/articles/article4.jpg';
import img from '../images/chillful/banner.jpg';
import banner from '../images/challenge/manatee_banner.jpg';
import img_event_1 from '../images/chillful/event_1.jpg';
//import Modal from 'react-modal';
//import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
  isDesktop,
  isIOS
} from "react-device-detect";
import { db } from '../firebase.js';
import PopupModal from '../components/PopupModalManatee';
//import { Helmet, HelmetProvider } from "react-helmet-async";

function Manatee14DayChallengeInfo() {

  function toDownloadManatee() {
    window.location.href = 'https://vivablee.com/manatee';
  }
  const ref = useRef();
  const openTooltip = () => ref.current.open();

  useEffect(() => {
    // to get the information for activity
    db.collection('users')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.docs.forEach((doc) => {
          console.log(doc.data()['email'])
        });
      });
  }, []);
  
  return (
    <center>
      <PopupModal Ref={ref} />
      <img
        style={{ maxWidth: '300px', width:"80vw", marginTop:30}}
        src={require('../images/general/splash_manatee.png')}
        alt="icon"
      ></img>
      <div style={{ maxWidth:"960px", textAlign: 'left', marginLeft:"20px", marginRight:"20px"}}>
      <div style={{ marginTop:20 }}></div>
        <center>
          <h1>manatee 中大14天冥想挑戰規則及條款</h1>
          <img
            style={{ maxWidth: '960px', width:"calc(100vw - 40px)", marginTop:30}}
            src={require('../images/challenge/manatee_banner.jpg')}
            alt="icon"
          ></img>
        </center>
      </div>
      <div style={{ maxWidth:"960px", textAlign: 'left', marginLeft:"20px", marginRight:"20px"}}>
        
        <h2 style={{ marginTop:30, marginBottom:10 }}>A. 基本資料</h2>
        <p>挑戰日期：1月30日 (00:00) - 2月12日 (23:59)</p>
        <p>參加資格：擁有中大電郵的學生、教職員及校友</p>
        <p>參加辦法：下載manatee應用程式，並以中大電郵登記帳戶， 便會自動參加是次挑戰</p>

        <h2 style={{ marginTop:30, marginBottom:10 }}>B. 挑戰安排</h2>
        <p>1. 所有以中大電郵註冊的 manatee 帳號，將會自動參加是次挑戰</p>
        <p>2. 於挑戰期間，使用 manatee 的內容冥想及完成任務，即可獲取積分</p>
        <p>3. 於挑戰完結後，參加者可按排名獲得相應獎品</p>
        <p>4. 於挑戰期間及前後，manatee 會以電郵溝通，並定期在 Instagram 上更新排名</p>

        <h2 style={{ marginTop:30, marginBottom:10 }}>C. 計分方法 (共660分)</h2>
        <p>1. 使用 manatee 的冥想旅程及練習 (@每日最多冥想30分鐘，共30分， 14天最高可獲420分)</p>
        <p>2. 於挑戰期間， 使用 manatee，完成最多四篇心情日記 (Mood Diary) (@每篇35分，共140分)</p>
        <p>3. 於挑戰完結前，完成用家報告，分享冥想的體驗，及對 manatee 產品的提議。(50+50分)</p>
        <p>* 報告形式不限， 可以文字或短片提交。 連結會於活動開始後提供，參加者須於挑戰完結前提交。完結後，即可獲得50分， 額外被揀選的用家報告，可獲得額外最高50分)</p>

        <h2 style={{ marginTop:30, marginBottom:10 }}>D. 額外任務 (共120分)</h2>
        <p>完成額外任務，亦可獲得額外積分：</p>
        <p>1. 推薦其他中大朋友參加 (成功推薦每位可得到10分，最多五位，共50分)</p>
        <p>2. Follow manatee instagram (15分)</p>
        <p>3. 於 manatee “14天冥想挑戰” 的post 下面留言 (15分)</p>
        <p>4. 下載manatee 夥伴應用程式 “Vivablee” 並註冊帳戶 (20分)</p>
        <p>5. Follow Vivablee Instagram (10分)</p>
        <p>6. 於 Vivablee “14天冥想挑戰” 的post 下面留言(10分)</p>

        <h2 style={{ marginTop:30, marginBottom:10 }}>E. 獎品</h2>
        <p>第一名：JW Marriot Staycation 套票 (價值 $2,500)</p>
        <p>第二名：第三代AirPods (價值 $1,700)</p>
        <p>第三及四名：YASHICA MF2 復刻菲林相機 (價值 $950)</p>
        <p>第五至二十四名: Just Coffee 精品咖啡店 $50 現金卷</p>
        <p>二十五至一百名: @Majeh__ 插畫水瓶一個</p>
        <p>101th Onwards: manatee 冰巾及口罩套</p>

        <p>特別獎: JBL Flip 5 藍牙喇叭 (2份)</p>
        <p>*所有完成14天挑戰的參加者(每天至少冥想5分鐘)，並完成報告的參加者，即有機會被選中 (名額2份)</p>

        <p>** 按照實際情況，manatee 團隊保留更改或以相應價值替代各項獎品的權利</p>

        <p>***Staycation 套票的類別及日期，由manatee團隊決定</p>

        <h2 style={{ marginTop:30, marginBottom:10 }}>F. 注意事項:</h2>
        <p>1. 參加者於參加本活動之同時，即同意接受本活動之細則及注意事項之規範；如有違反，主辦單位有權取消其參加或得獎資格。</p>
        <p>2. 參加者保證所有填寫或提出之資料均為真實且正確，且未冒用或盜用任何第三者之資料。如有不實之情，將被取消參加或得獎資格。</p>
        <p>3. manatee保留對各參加者，尤其得獎者，的身份進行核實及得分進行進一步核實的權利，所有未能核實的參加者，將會被取消資格。</p>
        <p>4. 如因無法通知得獎訊息時，manatee不負任何責任，且如有致損害於主辦單位或其他任何第三者，manatee亦不會就此負上任何責任。</p>
        <p>5. 參加者同意主辦單位於活動內所收集的個人資料，及其他數據，作日後分析及宣傳之用。</p>
        <p>6. 如有未盡事宜，主辦單位保留調整及最終解釋以及決策之權利。</p>

        <h2 style={{ marginTop:30, marginBottom:10 }}>G. 領獎資格說明：</h2>
        <p>1. 得獎名單將會於活動完結，及資料核實後，於電郵及IG公布。</p>
        <p>2. 得獎者需於限期內回覆相關資料，未於指定日期回覆者視為自動放棄並喪失領獎資格。</p>
        <p>3. 若得獎人不願或逾期提供相關資料、因個人資料異動無法取得聯繫或其他可歸責得獎人之事由致無法完成領獎手續等相關事宜者，視同放棄領獎資格，得獎人不得異宜。</p>
        <p>4. 得獎人不得指定獎項內容或要求更換、折現。</p>
        <p>5. 得獎資格不可轉讓或補發，並受有關條款約束，主辦單位保留隨時更換等值獎品之權利，且不必另行公告通知。</p>

      </div>
      <div onClick={isDesktop ? openTooltip : toDownloadManatee } style={{ width: 300, marginTop: 50, paddingTop: 15, paddingBottom: 15, borderRadius: 30, backgroundColor:'#5cd9c4', color:'white', cursor:'pointer', fontWeight:'600'  }}>
        立即下載 manatee 應用程式
      </div>
      <div style={{ height:80 }}></div>
    </center>
  );
}

export default Manatee14DayChallengeInfo;
