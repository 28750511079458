import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components'; // package to define css class
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import firebase from "firebase/app"
// import "firebase/auth"

const Col6 = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: middle;
`;

const MenuButton = styled.button`
  padding: 10px 60px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;

function LoginModal({Ref, setLoginFlag}) {

  const signinFunction = () => {
    // const auth = getAuth();
    let email = document.getElementById("email").value;
    let password = document.getElementById("password").value;
    firebase.auth().signInWithEmailAndPassword(email, password)
    // console.log(email)
    // console.log(password)
    .then((userCredential) => {
      // Signed in
      console.log("success")
      const user = userCredential.user;
      document.getElementById("loginCloseBtn").click();
      alert("登入成功")
      setLoginFlag(true);
      return
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage)
      console.log("failed")
      document.getElementById("loginCloseBtn").click();
      alert("登入失敗")
    });
  }

  // menu content
  return (
    <div>
      <Popup
        ref={Ref}
          modal
          nested
          repositionOnResize
          contentStyle={{ maxWidth: '400px', borderRadius: '25px' }}
      >
        {close => (
              <div className="amodal">
              <button className="close" onClick={close} id="loginCloseBtn">
                  &times;
              </button>
                  <Col6>
                  <center>
                  <div style={{ height:5 }}></div>
                  <h3 style={{ color: "#333", fontSize:32 }}>會員登入</h3>
                  <div style={{ height:20 }}></div>
                    <span style={{ fontSize:16, marginRight:5 }}>電郵：</span>
                      <input id="email" style={{ padding:5, width:'calc( 100% - 130px )' }}>
                      </input>
                      <div style={{ height:10 }}></div>
                      <span style={{ fontSize:16, marginRight:5 }}>密碼：</span>
                        <input id="password" type="password" style={{ padding:5, width:'calc( 100% - 130px )' }}>
                        </input>
                      <div style={{ height:20 }}></div>
                      <MenuButton onClick={() => signinFunction()}>登入</MenuButton>
                      <div style={{ height:10 }}></div>
                    </center>
                  </Col6>
              </div>

          )}
      </Popup>
  </div>
  );
}

export default LoginModal;
