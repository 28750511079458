// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Configuration, Coupon, Version, PurchasedActivity, Feed, Bell, JourneyHistory, JourneyProgress, BreatheVoice, JourneyCheckpoint, JourneyStage, Journey, Soundscape, Package, Quantity, ProductivityDailyHistory, Tag, ProductivityHistory, MoodDiary, Video, User, MeditationHistory, Meditation, Activity, ActivityProvider } = initSchema(schema);

export {
  Configuration,
  Coupon,
  Version,
  PurchasedActivity,
  Feed,
  Bell,
  JourneyHistory,
  JourneyProgress,
  BreatheVoice,
  JourneyCheckpoint,
  JourneyStage,
  Journey,
  Soundscape,
  Package,
  Quantity,
  ProductivityDailyHistory,
  Tag,
  ProductivityHistory,
  MoodDiary,
  Video,
  User,
  MeditationHistory,
  Meditation,
  Activity,
  ActivityProvider
};