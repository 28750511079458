/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react';
import ActivityCard from '../components/ActivityCard';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import IntroChillful from '../components/IntroChillful';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
import img from '../images/chillful/banner.png';
import img_event_1 from '../images/chillful/event_1.jpg';
import 'reactjs-popup/dist/index.css';
import {
  isDesktop,
  isIOS
} from "react-device-detect";
import { db } from '../firebase.js';
import PopupModal from '../components/PopupModal';
import { DataStore, Predicates, Storage } from 'aws-amplify';
import { Activity } from '../models';
import Skeleton from '@material-ui/lab/Skeleton';

function Chillful() {
  const [chillfulBannerTitle, setChillfulBannerTitle] = useState('');
  const [chillfulBannerDescription, setChillfulBannerDescription] = useState('');
  const chillfulActivityCategoryId = '';

  const [category, setCategory] = useState('');
  const [category_chi, setCategory_chi] = useState('');
  const [description, setDescription] = useState('');
  const [description_chi, setDescription_chi] = useState('');

  const [packages, setPackages] = useState(['']);
  const [packages_chi, setPackages_chi] = useState(['']);
  const [price, setPrice] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [subCategory_chi, setSubCategory_chi] = useState('');
  const [targets, setTargets] = useState(['']);
  const [targets_chi, setTargets_chi] = useState(['']);
  const [title, setTitle] = useState('');
  const [title_chi, setTitle_chi] = useState('');

  const [loader, setLoader] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [timeslots, setTimeslots] = useState([]);

  const [images, setImages] = useState([]);
  const [imagesURL, setImagesURL] = useState([]);

  const [isPopup, setIsPopup] = useState(false);

  function toChillful() {
    $('html, body').animate(
      {
        scrollTop: $('#demoScroll').offset().top,
      },
      1000,
    );
  }

  function toPartnershipsEmail() {
    window.location.href = 'mailto:partnerships@vivablee.com';
  }

  $('#container1').hover(
    function () {
      $('#imageCover1').css('transform', 'scale(1.2)');
    },
    function () {
      $('#imageCover1').css('transform', 'scale(1)');
    },
  );
  $('#container2').hover(
    function () {
      $('#imageCover2').css('transform', 'scale(1.2)');
    },
    function () {
      $('#imageCover2').css('transform', 'scale(1)');
    },
  );
  $('#container3').hover(
    function () {
      $('#imageCover3').css('transform', 'scale(1.2)');
    },
    function () {
      $('#imageCover3').css('transform', 'scale(1)');
    },
  );
  $('#container4').hover(
    function () {
      $('#imageCover4').css('transform', 'scale(1.2)');
    },
    function () {
      $('#imageCover4').css('transform', 'scale(1)');
    },
  );
  $('#container5').hover(
    function () {
      $('#imageCover5').css('transform', 'scale(1.2)');
    },
    function () {
      $('#imageCover5').css('transform', 'scale(1)');
    },
  );
  $('#container6').hover(
    function () {
      $('#imageCover6').css('transform', 'scale(1.2)');
    },
    function () {
      $('#imageCover6').css('transform', 'scale(1)');
    },
  );

  const ref = useRef();
  const openTooltip = () => ref.current.open();

  function toDownloadAppIOS() {
    window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
  }
  function toDownloadAppAndroid() {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
  }

  const [activityArray, setActivityArray] = useState([])
  const [activityExploreArray, setActivityExploreArray] = useState([])
  const [activityExploreImageArray, setActivityExploreImageArray] = useState([])

  useEffect(()=>{
    getActivity();
  },[])

  const generate5Random = (size) => {
    var arr = [];
    while(arr.length < 5){
        var r = Math.floor(Math.random() * size);
        if(arr.indexOf(r) === -1) arr.push(r);
    }
    console.log("arr:", arr)
    return arr
  }

  const getActivity = async () => {
    const activityList = await DataStore.query(Activity, c => c.validationFlag("eq", true));
    setActivityArray(activityList);

    var arr = generate5Random(activityList.length);
    for(let i = 0; i<5; i++){
      setActivityExploreArray((activityExploreImageArray) => [...activityExploreImageArray, activityList[arr[i]]])
      const url = getImageUrl(activityList[arr[i]].imagesLarge[0]).then((response)=>{
        setActivityExploreImageArray((activityExploreImageArray) => [...activityExploreImageArray, response])
      })
    }
  };

  const getImageUrl = async (image) => {
    const url = await Storage.get(image)
    return url;
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Menu page={'activities'} />
        <PopupModal Ref={ref} />
        {/* Be Chillful top banner */}

        <center>
          <ChillfulTopBannerDiv>
            <ChillfulTopBannerTable>
              <colgroup>
                <col style={{ width: '50%' }} />
                <col style={{ width: '50%' }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>
                    <ChillfulBannerText>
                      <ChillfulBannerIcon
                        style={{ marginBottom: '-3px' }}
                        src={require('../images/general/chillful_icon_original.png')}
                      ></ChillfulBannerIcon>
                      <ChillfulBannerText1>本土特色活動</ChillfulBannerText1>
                      <ChillfulBannerText2>
                        Chill At Home:
                        <br />
                        線上休閒活動體驗
                  </ChillfulBannerText2>
                      <ChillfulBannerText3>
                        Vivablee 提供一系列線上休閒活動體驗，讓你在家中亦能放鬆自己
                  </ChillfulBannerText3>
                      <ChillfulBannerBtn>
                        <ChillfulBannerBtnIcon
                          src={require('../images/general/play_white.png')}
                        ></ChillfulBannerBtnIcon>
                        <ChillfulBannerBtnText onClick={toChillful} style={{ color: 'white' }}>
                          了解更多
                    </ChillfulBannerBtnText>
                      </ChillfulBannerBtn>
                    </ChillfulBannerText>
                    <div style={{ marginTop: 20 }}>
                      想加盟成為導師？請立即
                  <ChillfulBannerSpan onClick={toPartnershipsEmail}>聯絡我們</ChillfulBannerSpan>！
                </div>
                  </td>

                  <td>
                    <ChillfulBanner />
                  </td>
                </tr>
              </tbody>
            </ChillfulTopBannerTable>
            <ChillfulTopBannerTable768 style={{ width: '100%' }}>
              <colgroup>
                <col style={{ width: '100%' }} />
              </colgroup>
              <tbody style={{ width: '100%' }}>
                <tr style={{ width: '100%' }}>
                  <td style={{ width: '100%' }}>
                    <ChillfulBannerText>
                      <ChillfulBannerIcon
                        style={{ marginBottom: '-3px' }}
                        src={require('../images/general/chillful_icon_original.png')}
                      ></ChillfulBannerIcon>
                      <ChillfulBannerText1>本土特色活動</ChillfulBannerText1>
                      <ChillfulBannerText2>
                        Chill At Home: <br />
                      線上休閒活動體驗
                  </ChillfulBannerText2>
                      <ChillfulBannerText3>
                        Vivablee 提供一系列線上休閒活動體驗，讓你在家中亦能放鬆自己
                  </ChillfulBannerText3>
                      <ChillfulBannerBtn>
                        <ChillfulBannerBtnIcon
                          src={require('../images/general/play_white.png')}
                        ></ChillfulBannerBtnIcon>
                        <ChillfulBannerBtnText onClick={toChillful} style={{ color: 'white' }}>
                          了解更多
                    </ChillfulBannerBtnText>
                      </ChillfulBannerBtn>
                    </ChillfulBannerText>
                    <div style={{ marginTop: 20 }}>
                      想加盟成為導師？請立即
                  <ChillfulBannerSpan onClick={toPartnershipsEmail}>聯絡我們</ChillfulBannerSpan>！
                </div>
                  </td>
                </tr>
                <tr style={{ width: '100%' }}>
                  <td style={{ width: '100%' }}>
                    <ChillfulBanner style={{ width: '100%', height: '300px', marginTop: '30px' }} />
                  </td>
                </tr>
              </tbody>
            </ChillfulTopBannerTable768>
          </ChillfulTopBannerDiv>
        </center>

        <svg
          style={{ backgroundColor: 'white', marginBottom: '-10px' }}
          width="100%"
          //height="auto"
          viewBox="0 0 3840 260"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M-9.09494702e-13,0.5 L-9.09494702e-13,259.5 C543.289571,86.8333333 1184.99624,0.5 1925.12,0.5 C2499.39149,0.5 3137.68482,86.8333333 3840,259.5 L3840,0.5 L-9.09494702e-13,0.5 Z"
              id="Path"
              fill="#f9f9f9"
              transform="translate(1920.000000, 130.000000) scale(1, -1) translate(-1920.000000, -130.000000) "
            ></path>
          </g>
        </svg>

        <div id="demoScroll"></div>

        <div
          id="browseActivityScroll"
          style={{ paddingTop: '180px', paddingBottom: '0px', backgroundColor: '#f9f9f9' }}
        >
          <ChillfulExploreTitle>探索活動體驗</ChillfulExploreTitle>
        </div>

        <ChillfulBody>
          <ChillfulActivityListStyle2>
            <table style={{ width: '100%' }} cellSpacing="0" cellPadding="0">
              <colgroup>
                <ChillfulColWidth50 />
                <ChillfulColWidth25Long2 />
                <ChillfulColWidth25Long />
              </colgroup>
              <tbody>
                <tr>
                  <td rowSpan="2">
                    {activityExploreArray[0] &&
                    <ChillfulActivityListStyle2ContainerDiv1 id={'container1'}
                      onClick={() => window.location.href = '/Activities/Details/' + activityExploreArray[0].id}
                    >
                      <ChillfulActivityListStyle2Container>
                        {activityExploreImageArray[0] &&
                          <ChillfulActivityListStyle2ImageCover
                            id={'imageCover1'}
                            style={{ backgroundImage: 'url(' + activityExploreImageArray[0] + ')' }}
                          />
                        }
                      </ChillfulActivityListStyle2Container>
                      <ChillfulActivityListStyle2ClassBottom>
                        <ChillfulActivityListIcon
                          src={require('../images/general/chillful_icon_original.png')}
                        ></ChillfulActivityListIcon>
                        <ChillfulActivityListStyle2ClassBottomText1>
                          {activityExploreArray[0].categoryChi}
                        </ChillfulActivityListStyle2ClassBottomText1>
                        <ChillfulActivityListStyle2ClassBottomText2>
                          {activityExploreArray[0].titleChi}
                        </ChillfulActivityListStyle2ClassBottomText2>
                        <ChillfulActivityListStyle2ClassBottomText3>
                          {activityExploreArray[0].descriptionChi}
                        </ChillfulActivityListStyle2ClassBottomText3>
                        <ChillfulActivityListStyle2ClassBottomText4>
                          {activityExploreArray[0].subcategoryChi} ● {activityExploreArray[0].duration/3600} 小時
                        </ChillfulActivityListStyle2ClassBottomText4>
                      </ChillfulActivityListStyle2ClassBottom>
                    </ChillfulActivityListStyle2ContainerDiv1>
                    }
                  </td>

                  <ChillfulActivityListStyle2ContainerTd>
                    {activityExploreArray[1] &&
                    <ChillfulActivityListStyle2ContainerDiv2 id={'container2'} onClick={() => window.location.href = '/Activities/Details/' + activityExploreArray[1].id}>
                      <ChillfulActivityListStyle2Container>
                      {activityExploreImageArray[1] &&
                        <ChillfulActivityListStyle2ImageCover
                          id={'imageCover2'}
                          style={{ backgroundImage: 'url(' + activityExploreImageArray[1] + ')' }}
                        />
                      }
                      </ChillfulActivityListStyle2Container>
                      <ChillfulActivityListStyle2ClassBottom>
                        <ChillfulActivityListIcon
                          src={require('../images/general/chillful_icon_original.png')}
                        ></ChillfulActivityListIcon>
                        <ChillfulActivityListStyle2ClassBottomText1>
                          {activityExploreArray[1].categoryChi}
                        </ChillfulActivityListStyle2ClassBottomText1>
                        <ChillfulActivityListStyle2ClassBottomText2>
                          {activityExploreArray[1].titleChi}{' '}
                        </ChillfulActivityListStyle2ClassBottomText2>
                        <ChillfulActivityListStyle2ClassBottomText3>
                          {activityExploreArray[1].descriptionChi}
                        </ChillfulActivityListStyle2ClassBottomText3>
                        <ChillfulActivityListStyle2ClassBottomText4>
                          {activityExploreArray[1].subcategoryChi} ● {activityExploreArray[1].duration/3600} 小時
                        </ChillfulActivityListStyle2ClassBottomText4>
                      </ChillfulActivityListStyle2ClassBottom>
                    </ChillfulActivityListStyle2ContainerDiv2>
                    }
                  </ChillfulActivityListStyle2ContainerTd>

                  <ChillfulActivityListStyle2TdLong2>
                    {activityExploreArray[1] &&
                    <ChillfulActivityListStyle2Small1 id={'container3'} onClick={() => window.location.href = '/Activities/Details/' + activityExploreArray[1].id}>
                      <ChillfulActivityListStyle2Container2>
                        {activityExploreImageArray[1] &&
                          <ChillfulActivityListStyle2ImageCover
                            id={'imageCover3'}
                            style={{ backgroundImage: 'url(' + activityExploreImageArray[1] + ')' }}
                          />
                        }
                      </ChillfulActivityListStyle2Container2>
                      <ChillfulActivityListStyle2ClassBottom2>
                        <ChillfulActivityListIcon
                          src={require('../images/general/chillful_icon_original.png')}
                        ></ChillfulActivityListIcon>
                        <ChillfulActivityListStyle2ClassBottomText1>
                          {activityExploreArray[1].categoryChi}
                        </ChillfulActivityListStyle2ClassBottomText1>
                        <ChillfulActivityListStyle2ClassBottomSmallText2>
                          {activityExploreArray[1].titleChi}
                        </ChillfulActivityListStyle2ClassBottomSmallText2>
                        <ChillfulActivityListStyle2ClassBottomText4>
                          {activityExploreArray[1].subcategoryChi} ● {activityExploreArray[1].duration/3600} 小時
                        </ChillfulActivityListStyle2ClassBottomText4>
                      </ChillfulActivityListStyle2ClassBottom2>
                    </ChillfulActivityListStyle2Small1>
                    }
                  </ChillfulActivityListStyle2TdLong2>

                  <ChillfulActivityListStyle2TdLong>
                    {activityExploreArray[2] &&
                    <ChillfulActivityListStyle2Small1 id={'container4'} onClick={() => window.location.href = '/Activities/Details/' + activityExploreArray[2].id}>
                      <ChillfulActivityListStyle2Container2>
                        {activityExploreImageArray[2] &&
                          <ChillfulActivityListStyle2ImageCover
                            id={'imageCover4'}
                            style={{ backgroundImage: 'url(' + activityExploreImageArray[2] + ')' }}
                          />
                        }
                      </ChillfulActivityListStyle2Container2>
                      <ChillfulActivityListStyle2ClassBottom2>
                        <ChillfulActivityListIcon
                          src={require('../images/general/chillful_icon_original.png')}
                        ></ChillfulActivityListIcon>
                        <ChillfulActivityListStyle2ClassBottomText1>
                          {activityExploreArray[2].categoryChi}
                        </ChillfulActivityListStyle2ClassBottomText1>
                        <ChillfulActivityListStyle2ClassBottomSmallText2>
                          {activityExploreArray[2].titleChi}
                        </ChillfulActivityListStyle2ClassBottomSmallText2>
                        <ChillfulActivityListStyle2ClassBottomText4>
                          {activityExploreArray[2].subcategoryChi} ● {activityExploreArray[2].duration/3600} 小時
                        </ChillfulActivityListStyle2ClassBottomText4>
                      </ChillfulActivityListStyle2ClassBottom2>
                    </ChillfulActivityListStyle2Small1>
                    }
                  </ChillfulActivityListStyle2TdLong>
                </tr>
                <tr>
                  <td colSpan="2">
                    <ChillfulActivityListStyle2Small2 id={'container5'} onClick={() => window.location.href = '/Activities/Details/' + activityExploreArray[3].id}>
                      {activityExploreArray[3] &&
                      <>
                        <ChillfulActivityListStyle2Container3>
                          {activityExploreImageArray[3] &&
                            <ChillfulActivityListStyle2ImageCover
                              id={'imageCover5'}
                              style={{ backgroundImage: 'url(' + activityExploreImageArray[3] + ')' }}
                            />
                          }
                        </ChillfulActivityListStyle2Container3>
                        <ChillfulActivityListStyle2ClassBottom>
                          <ChillfulActivityListIcon
                            src={require('../images/general/chillful_icon_original.png')}
                          ></ChillfulActivityListIcon>
                          <ChillfulActivityListStyle2ClassBottomText1>
                            {activityExploreArray[3].categoryChi}
                          </ChillfulActivityListStyle2ClassBottomText1>
                          <ChillfulActivityListStyle2ClassBottomText2>
                            {activityExploreArray[3].titleChi}
                          </ChillfulActivityListStyle2ClassBottomText2>
                          <ChillfulActivityListStyle2ClassBottomText3>
                            {activityExploreArray[3].descriptionChi}
                          </ChillfulActivityListStyle2ClassBottomText3>
                          <ChillfulActivityListStyle2ClassBottomText4>
                            {activityExploreArray[3].subcategoryChi} ● {activityExploreArray[3].duration/3600} 小時
                          </ChillfulActivityListStyle2ClassBottomText4>
                        </ChillfulActivityListStyle2ClassBottom>
                        </>
                      }
                    </ChillfulActivityListStyle2Small2>

                    <ChillfulActivityListStyle2Small3 id={'container6'} onClick={() => window.location.href = '/Activities/Details/' + activityExploreArray[2].id}>
                      {activityExploreArray[2] &&
                        <>
                          <ChillfulActivityListStyle2Container2>
                            {activityExploreImageArray[2] &&
                              <ChillfulActivityListStyle2ImageCover
                                id={'imageCover6'}
                                style={{ backgroundImage: 'url(' + activityExploreImageArray[2] + ')' }}
                              />
                            }
                          </ChillfulActivityListStyle2Container2>
                          <ChillfulActivityListStyle2ClassBottom2>
                            <ChillfulActivityListIcon
                              src={require('../images/general/chillful_icon_original.png')}
                            ></ChillfulActivityListIcon>
                            <ChillfulActivityListStyle2ClassBottomText1>
                              {activityExploreArray[2].categoryChi}
                            </ChillfulActivityListStyle2ClassBottomText1>
                            <ChillfulActivityListStyle2ClassBottomSmallText2>
                              {activityExploreArray[2].titleChi}
                            </ChillfulActivityListStyle2ClassBottomSmallText2>
                            <ChillfulActivityListStyle2ClassBottomText4>
                              {activityExploreArray[2].subcategoryChi} ● {activityExploreArray[2].duration/3600} 小時
                            </ChillfulActivityListStyle2ClassBottomText4>
                          </ChillfulActivityListStyle2ClassBottom2>
                        </>
                      }
                    </ChillfulActivityListStyle2Small3>
                  </td>
                </tr>
              </tbody>
            </table>
          </ChillfulActivityListStyle2>

          <svg
            style={{ backgroundColor: 'white', marginTop: '100px', marginBottom: '-7px' }}
            width="100%"
            //height="auto"
            viewBox="0 0 3840 259"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Path</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path
                d="M0,0 C539.876237,172.666667 1179.87624,259 1920,259 C2494.27149,259 3134.27149,172.666667 3840,0 L0,0 Z"
                id="Path"
                fill="#f9f9f9"
              ></path>
            </g>
          </svg>

          <BrowseArticlesDiv
            style={{ paddingBottom: '150px', paddingTop: '100px', backgroundColor: 'white' }}
          >
            <BrowseArticlesTitle>報名流程</BrowseArticlesTitle>

            <IntroChillful style={{ backgroundColor: 'white' }} />
          </BrowseArticlesDiv>

          <div style={{ backgroundColor: 'white', height: 20, width: '100%', marginBottom: -20 }}></div>

          <svg
            style={{ backgroundColor: 'white', marginTop: '0px', marginBottom: '-30px' }}
            width="100%"
            //height="auto"
            viewBox="0 0 3815 394"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Path 6</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path
                d="M-1.8189894e-12,259 C459.075022,86.3333333 973.340227,-2.27373675e-13 1542.79561,-2.27373675e-13 C2112.251,-2.27373675e-13 2869.65246,131.323801 3815,393.971403 L-1.8189894e-12,393.971403 L-1.8189894e-12,259 Z"
                id="Path-6"
                fill="#f9f9f9"
              ></path>
            </g>
          </svg>

          <BrowseActivitiesDiv>
            <BrowseActivitiesTitle>活動精選</BrowseActivitiesTitle>
          </BrowseActivitiesDiv>

          <BrowseActivitiesMargin />

          <ChillfulActivityListStyle1>
            <ChillfulActivityListStyle1TitleDiv>
              <ChillfulActivityListStyle1Title>熱門體驗</ChillfulActivityListStyle1Title>
              <ChillfulActivityListStyle2Arrow
                src={require('../images/general/arrow_right_black.png')}
              ></ChillfulActivityListStyle2Arrow>
            </ChillfulActivityListStyle1TitleDiv>
            <ChillfulMargin40 />

            {activityArray.length > 0 ?
            <ChillfulActivityListStyle1Table>
              <tbody>
                <tr>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test1'} data={activityArray[0]} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test2'} data={activityArray[1]} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test3'} data={activityArray[2]} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test4'} data={activityArray[3]} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test5'} data={activityArray[4]} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                </tr>
              </tbody>
            </ChillfulActivityListStyle1Table>
            :
            <ChillfulActivityListStyle1Table>
              <tbody>
                <tr>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                      style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                    />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                      style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                    />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                      style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                    />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                      style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                    />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                      style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                    />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                </tr>
              </tbody>
            </ChillfulActivityListStyle1Table>
            }
          </ChillfulActivityListStyle1>

          <ChillfulActivityListStyle1 style={{ marginTop: '-150px' }}>
            <ChillfulActivityListStyle1TitleDiv>
              <ChillfulActivityListStyle1Title>精選活動</ChillfulActivityListStyle1Title>
              <ChillfulActivityListStyle2Arrow
                src={require('../images/general/arrow_right_black.png')}
              ></ChillfulActivityListStyle2Arrow>
            </ChillfulActivityListStyle1TitleDiv>
            <ChillfulMargin40 />

            {activityArray.length > 0 ?
            <ChillfulActivityListStyle1Table>
              <tbody>
                <tr>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test6'} data={activityArray[5]} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test7'} data={activityArray[6]} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test8'} data={activityArray[7]} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test9'} data={activityArray[8]} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <ActivityCard shadow={true} id={'test10'} data={activityArray[9]} />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                </tr>
              </tbody>
            </ChillfulActivityListStyle1Table>
            :
            <ChillfulActivityListStyle1Table>
              <tbody>
                <tr>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                      style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                    />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                      style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                    />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                      style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                    />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                      style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                    />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin2 />
                  </td>
                  <ChillfulActivityListStyle1Td>
                    <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                      style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                    />
                  </ChillfulActivityListStyle1Td>
                  <td>
                    <ChillfulActivityListStyle1Margin />
                  </td>
                </tr>
              </tbody>
            </ChillfulActivityListStyle1Table>
            }
          </ChillfulActivityListStyle1>
        </ChillfulBody>

        <svg
          style={{ marginTop: '-50px', backgroundColor: 'white' }}
          width="100%"
          //height="auto"
          viewBox="0 0 3840 259"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M0,0 C539.876237,172.666667 1179.87624,259 1920,259 C2494.27149,259 3134.27149,172.666667 3840,0 L0,0 Z"
              id="Path"
              fill="#f9f9f9"
            ></path>
          </g>
        </svg>

        <Footer />
      </div>
    </div>
  );
}

export default Chillful;

const ChillfulMargin40 = styled.div`
  height: 40px;
`;
const ChillfulBanner = styled.div`
  height: 600px;
  background-size: cover;
  background-image: url(${img});
  background-position: center;
  border-radius: 30px;
`;
const ChillfulMargin160 = styled.div`
  height: 160px;
`;
const ChillfulBannerText = styled.div`
  margin-right: 100px;
  transition: all 0.5s;
  @media only screen and (max-width: 1024px) {
    margin-right: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin-right: 30px;
  }
`;
const ChillfulBannerText1 = styled.span`
  margin-left: 10px;
  color: #ff585d;
  font-weight: 800;
  font-size: 24px;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    font-size: 18px;
  }
`;
const ChillfulBannerText2 = styled.p`
  font-weight: 600;
  font-size: 42px;
  margin-top: 10px;
  transition: all 0.5s;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 36px;
  }
`;
const ChillfulBannerText3 = styled.p`
  font-weight: 500;
  font-size: 24px;
  margin-top: 5px;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
  }
`;

const ChillfulBannerBtn = styled.div`
  margin-top: 15px;
  background-color: #ff585d;
  padding: 10px 20px;
  width: 100px;
  border-radius: 50px;
  cursor: pointer;
  @media only screen and (max-width: 800px) {
    padding: 9px 18px;
    width: 92px;
  }
`;
const ChillfulBannerBtnIcon = styled.img`
  width: 17px;
  vertical-align: middle;
  margin-bottom: 6px;
  margin-right: 4px;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
`;
const ChillfulBannerBtnText = styled.span`
  margin-left: 5px;
  font-size: 18px;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
`;
const ChillfulBannerIcon = styled.img`
  height: 24px;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    height: 18px;
  }
`;
const ChillfulBody = styled.div`
  background-color: #f9f9f9;
  width: 100%;
`;

const ChillfulActivityListStyle2 = styled.div`
  margin-left: 150px;
  margin-right: 150px;
  @media only screen and (max-width: 1300px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
const ChillfulActivityListStyle2Arrow = styled.img`
  height: 16px;
  vertical-align: middle;
  margin-bottom: 6px;
  margin-top: 1px;
  margin-left: 5px;
`;

const ChillfulColWidth50 = styled.col`
  width: 50%;
  @media only screen and (max-width: 800px) {
    width: 50%;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const ChillfulColWidth25Long2 = styled.col`
  width: 25%;
  @media only screen and (max-width: 800px) {
    width: 50%;
  }
  @media only screen and (max-width: 600px) {
    width: 0%;
  }
`;
const ChillfulColWidth25Long = styled.col`
  width: 25%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const ChillfulActivityListIcon = styled.img`
  height: 12px;
  margin-right: 2px;
  display: inline;
`;
const ChillfulActivityListStyle2Container = styled.div`
  overflow: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  width: 100%;
  padding-top: calc(80% - 36px);
`;
const ChillfulActivityListStyle2ContainerTd = styled.td`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
  }
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2ContainerDiv1 = styled.div`
  cursor: pointer;
  @media only screen and (max-width: 800px) {
    margin-right: 5px;
  }
`;
const ChillfulActivityListStyle2ContainerDiv2 = styled.div`
  cursor: pointer;
  @media only screen and (max-width: 800px) {
    margin-left: 5px;
  }
`;
const ChillfulActivityListStyle2TdLong = styled.td`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2TdLong2 = styled.td`
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2ImageCover = styled.div`
  background-size: cover;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${img_event_1});
  transition: all 1s;
  background-position: center;
`;
const ChillfulActivityListStyle2ClassBottom = styled.div`
  height: 118px;
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 15px 20px 0px 20px;
  color: black;
  @media only screen and (max-width: 800px) {
    height: 100px;
  }
`;
const ChillfulActivityListStyle2ClassBottomText1 = styled.div`
  font-size: 14px;
  color: #ff585d;
  display: inline;
  margin-left: 3px;
  font-weight: 700;
  text-transform: uppercase;
`;
const ChillfulActivityListStyle2ClassBottomText2 = styled.div`
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
const ChillfulActivityListStyle2ClassBottomSmallText2 = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const ChillfulActivityListStyle2ClassBottomText3 = styled.div`
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const ChillfulActivityListStyle2ClassBottomText4 = styled.div`
  font-size: 16px;
  margin-top: 4px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const ChillfulActivityListStyle2Container2 = styled.div`
  overflow: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  width: 100%;
  padding-top: calc(80% - 72px);
  @media only screen and (max-width: 1200px) {
    padding-top: calc(80% - 77px);
  }
`;
const ChillfulActivityListStyle2Container3 = styled.div`
  overflow: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  width: 100%;
  padding-top: calc(40% - 72px);
`;
const ChillfulActivityListStyle2ClassBottom2 = styled.div`
  transition: height 0.5s;
  height: 100px;
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 10px 20px 0px 20px;
  color: black;
  @media only screen and (max-width: 1200px) {
    height: 118px;
    padding: 15px 20px 0px 20px;
  }
`;
const ChillfulActivityListStyle2Small1 = styled.div`
  margin-left: 24px;
  cursor: pointer;
`;
const ChillfulActivityListStyle2Small2 = styled.div`
  margin-left: 24px;
  margin-top: 24px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
const ChillfulActivityListStyle2Small3 = styled.div`
  margin-left: 24px;
  margin-top: 24px;
  display: none;
  cursor: pointer;
  width: calc(100% - 24px);
  @media only screen and (max-width: 1200px) {
    display: inline-block;
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
const ChillfulActivityListStyle1 = styled.div`
  overflow-x: scroll;
  height: 500px;
  padding-top: 70px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ChillfulActivityListStyle1Title = styled.span`
  font-weight: 600;
  font-size: 24px;
`;
const ChillfulActivityListStyle1TitleDiv = styled.div`
  position: absolute;
  left: 100px;
  @media only screen and (max-width: 1300px) {
    left: 50px;
  }
  @media only screen and (max-width: 900px) {
    left: 20px;
  }
`;
const ChillfulActivityListStyle1Margin = styled.div`
  width: 100px;
  @media only screen and (max-width: 1300px) {
    width: 50px;
  }
  @media only screen and (max-width: 900px) {
    width: 20px;
  }
`;
const ChillfulActivityListStyle1Margin2 = styled.div`
  width: 15px;
`;
const ChillfulActivityListStyle1Td = styled.td`
  min-width: 350px;
  max-width: 350px;
`;
const ChillfulActivityListStyle1Table = styled.table`
  width: 100%;
`;

const ChillfulTopBannerDiv = styled.div`
  padding: 50px 150px 100px 150px;
  @media only screen and (max-width: 1024px) {
    padding: 50px 80px 100px 80px;
  }
  @media only screen and (max-width: 768px) {
    padding: 50px 30px 100px 30px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 20px 100px 20px;
  }
`;

const ChillfulTopBannerTable = styled.table`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ChillfulTopBannerTable768 = styled.table`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const ChillfulExploreTitle = styled.h1`
  font-size: 60px;
  font-weight: 700;
  color: #444;
  text-align: center;
  padding-bottom: 25px;
  padding-left: 150px;
  padding-right: 150px;
  @media only screen and (max-width: 1024px) {
    font-size: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const BrowseArticlesTitle = styled.h1`
  font-size: 60px;
  color: #555;
  margin-left: 30px;
  z-index: 0;
  position: relative;
  line-height: 90px;
  margin-bottom: 45px;
  @media only screen and (max-width: 768px) {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 50px;
    margin-left: 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 40px;
    line-height: 40px;
    margin-left: 20px;
    margin-bottom: 55px;
  }
`;

const BrowseArticlesDiv = styled.div`
  padding-left: 150px;
  padding-right: 150px;
  @media only screen and (max-width: 1200px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const BrowseActivitiesDiv = styled.div`
  position: absolute;
  right: 100px;
  margin-top: -20px;
  @media only screen and (max-width: 1200px) {
    margin-top: -15px;
    right: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 30px;
    left: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 40px;
    left: 20px;
  }
`;

const BrowseActivitiesTitle = styled.h1`
  font-size: 60px;
  color: #444;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const BrowseActivitiesMargin = styled.div`
  height: 30px;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const ChillfulBannerSpan = styled.span`
  cursor: pointer;
  color: #ff585d;
  font-weight: 600;
  :hover {
    text-decoration: underline;
  }
`;

