/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react';
import ActivityCard from '../components/ActivityCard';
import ActivitySearchBox from '../components/ActivitySearchBox';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
import img from '../images/chillful/event_1.jpg';
import { db } from '../firebase.js';
import Slider from 'infinite-react-carousel';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { DataStore, Storage } from 'aws-amplify';
import { Activity, ActivityProvider, Package, Quantity } from '../models';
import Skeleton from '@material-ui/lab/Skeleton';
import { useParams } from 'react-router-dom';
import {
  isDesktop,
  isIOS
} from "react-device-detect";

// define js function
function minus_quality(id) {
  const q = parseInt($('#' + id + 'Quantity').val(), 10);
  if (q > 0) {
    $('#' + id + 'Quantity').val(q - 1);
  }
}
function plus_quality(id) {
  const q = parseInt($('#' + id + 'Quantity').val(), 10);
  $('#' + id + 'Quantity').val(q + 1);
}

$(document).click(async function (event) {
  const $target = $(event.target);
  if (
    !$target.closest('#calendar').length &&
    !$target.closest('#calendarBtn').length &&
    $('#calendar').is(':visible')
  ) {
    $('#calendar').css('opacity', '0');
    $('#calendar').css('visibility', 'hide');
    $('#calendar').css('zIndex', '-1');
  }
});

function ActivityFunction() {

  // const activityID = "5460d5bf-a68d-4f20-9304-3f7737fe46a5";

  const { t, i18n } = useTranslation();

  const { activityID } = useParams();

  const [activityArray, setActivityArray] = useState([]);
  const [activityDetail, setActivityDetail] = useState();
  const [activityProviderDetail, setActivityProviderDetail] = useState();
  const [thumbnailURL, setThumbnailURL] = useState();
  const [providerThumbnailURL, setProviderThumbnailURL] = useState();
  const [displayThumbnailURL, setDisplayThumbnailURL] = useState();
  const [displayPackageDetail, setDisplayPackageDetail] = useState();
  const [displayQuantityDetail, setDisplayQuantityDetail] = useState();

  useEffect(()=>{
    getActivity();
  },[])

  const getActivity = async () => {

    const activityList = await DataStore.query(Activity, c => c.validationFlag("eq", true));
    setActivityArray(activityList);
    const activityDetailDB = await DataStore.query(Activity, activityID);
    setActivityDetail(activityDetailDB);
    getImageUrl(activityDetailDB.imagesLarge)

    const activityProviderList = await DataStore.query(ActivityProvider, activityDetailDB.activityproviderID);
    setActivityProviderDetail(activityProviderList);
    getProviderImageUrl(activityProviderList.logo);

    const packageList = await DataStore.query(Package, c => c.activityID("eq", activityID));
    getDisplayPackageDetail(packageList)

    const quantityList = await DataStore.query(Quantity, c => c.packageID("eq", packageList[0].id));
    getDisplayQuantityDetail(quantityList)
  };

  const getImageUrl = async (imagesLarge) => {
    let tmpURL = []
    let tmpDisplayURL = []
    for(let i=0; i < imagesLarge.length; i++){
        const url = await Storage.get(imagesLarge[i]);
        tmpURL.push(url)
        tmpDisplayURL.push(<div style={{ display:'inline-block', width: 200, height: 200, borderRadius: 20, marginRight:20, backgroundPosition: "center", backgroundSize: "cover", backgroundImage: 'url(' + url + ')' }} />)  
    }
    setThumbnailURL(tmpURL);
    setDisplayThumbnailURL(tmpDisplayURL)
  };

  const getDisplayPackageDetail = (packageList) => {
    let tmpDisplayPackageDetail = []
    for(let i=0; i < packageList.length; i++){
      tmpDisplayPackageDetail.push(
        <div style={{ cursor: i > 0 ? "not-allowed" : "pointer", marginLeft: 20, marginRight: 20, marginTop: 10, padding: 15, color:  i > 0 ? 'black' : '#de6b75' , fontWeight:'bold', border: i > 0 ? "1.5px solid #aaa" : "1.5px solid #de6b75", borderRadius: 10 }}>
          <p style={{ fontSize: 14 }}>{packageList[i].titleChi}</p>
          <p style={{ fontSize: 12, color: "#555" }}>{packageList[i].descriptionChi}</p>
        </div>
      )
    }
    setDisplayPackageDetail(tmpDisplayPackageDetail)
  }

  const getDisplayQuantityDetail = (quantityList) => {
    let tmpDisplayQuantityDetail = []
    tmpDisplayQuantityDetail.push(
      <div style={{ marginLeft: 20, marginRight: 20, marginTop: 10, padding: 15, fontWeight:'bold', border: "1.5px solid #aaa", borderRadius: 10 }}>
        <table style={{ width:'100%', marginRight:"auto" }}>
          <tbody>
            <tr>
              <td>
              <p style={{ fontSize: 14 }}>參加者</p>
              <p style={{ fontSize: 12, color: "#555" }}>價錢: HKD${quantityList[0].discountedPrice}</p>
              </td>
              <td style={{ textAlign:'right' }}>
                <div style={{ display:'inline', textAlign:'right' }}>
                  <div style={{ display:'inline-block', backgroundColor:'#aaa', borderRadius:1000, color:'white', padding:"0px 6px", fontSize: 14, cursor:'not-allowed' }}>-</div>
                  <div style={{ display:'inline-block', width:70, textAlign: 'center' }}>0</div>
                  <div style={{ display:'inline-block', backgroundColor:'#fe585d', borderRadius:1000, color:'white', padding:"0px 5px", fontSize: 14, cursor:'not-allowed' }}>+</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        
        
      </div>
    )
    setDisplayQuantityDetail(tmpDisplayQuantityDetail)
  }

  const getProviderImageUrl = async (logo) => {
    const url = await Storage.get(logo);
    setProviderThumbnailURL(url);
  };

  const orderActivityPosition = (bottomOffsetVar) => {
      var currentScroll = $(window).scrollTop() + $(window).height();    
      if (currentScroll >= bottomOffsetVar) {   
        $('#orderActivity').css({                      // scroll to that element or below it
          position: 'static'
        });
        $('#orderActivityTd').css({                      // scroll to that element or below it
          verticalAlign: 'bottom'
        });
      } else {
        $('#orderActivity').css({                      // scroll to that element or below it
          position: 'fixed'
        });
        $('#orderActivityTd').css({                      // scroll to that element or below it
          verticalAlign: 'top'
        });
      }    
  }

  const inputRef = useRef()
  useEffect(() => {
    const { offsetTop, offsetLeft, offsetHeight } = inputRef.current; 
    window.addEventListener("scroll", function(){
      orderActivityPosition(offsetTop);
  }, false);
    return () => {
    window.removeEventListener("scroll", orderActivityPosition);
    };
  }, [activityDetail, activityProviderDetail]);

  function toDownloadAppIOS() {
    window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
  }
  function toDownloadAppAndroid() {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
  }

  return (
    <div style={{ width: '100%' }}>
      <Menu page={'chillful'} shadow />

      <ActivityDetailContainer>
        <tbody>
          <colgroup>
            <col style={{ width: 'calc( 100% - 420px )' }} />
            <col style={{ width: '420px' }} />
          </colgroup>
          <tr>
            <td style={{ verticalAlign: 'top', width: '100%' }}>
              {/* <ActivityBannerDiv>
                <Slider dots>
                  <ActivityBanner></ActivityBanner>
                  <ActivityBanner></ActivityBanner>
                </Slider>
              </ActivityBannerDiv> */}

              {thumbnailURL ?
                <div style={{ height: 300, marginTop: 50, borderRadius: 20, backgroundPosition: "center", backgroundSize: "cover", backgroundImage: 'url(' + thumbnailURL[0] + ')' }}>
                  
                </div>
                :
                <div style={{ height:300, marginTop: 50 }}></div>
              }

              <ActivityDetailDiv>
              <div style={{ display:'inline' }}>
              <img
                style={{ width: 14, display:'inline', verticalAlign: 'middle' }}
                src={require('../images/general/chillful_icon_original.png')}
              />
              <p style={{ display:'inline', color:'#fe585d', fontSize:14, marginLeft: 5, fontWeight:'bold', verticalAlign: 'middle' }}>{activityDetail && activityDetail.categoryChi}</p>
              </div>
                        <ActivityDetailTableHeading>
                          {activityDetail && activityDetail.titleChi}
                        </ActivityDetailTableHeading>

                          <ActivityDetailTableReview>{activityDetail && activityDetail.subcategoryChi} ● {activityDetail && activityDetail.duration/3600} 小時</ActivityDetailTableReview>

                        <ActivityDetailTableSpacing></ActivityDetailTableSpacing>

                        <ActivityDetailTableDiv2>
                          <ActivityDetailTableDiv2Text>語言: {activityDetail && activityDetail.languageChi}</ActivityDetailTableDiv2Text>
                          <ActivityDetailTableDiv2Text>地點: {activityDetail && activityDetail.venueChi}</ActivityDetailTableDiv2Text>
                          <ActivityDetailTableDiv2Text>價錢: ${activityDetail && activityDetail.priceMin}+</ActivityDetailTableDiv2Text>
                        </ActivityDetailTableDiv2>

                        <ActivityDetailTableSpacing></ActivityDetailTableSpacing>

                        <h4 style={{ color:'#fe585d', fontSize: 16, marginBottom: 10, marginTop: 3 }}>活動詳情</h4>

                        <p style={{ marginBottom: 20 }}>{activityDetail && activityDetail.descriptionChi}</p>

                        <ActivityImageContainer>
                          {displayThumbnailURL ? displayThumbnailURL : <div style={{ height:200 }}></div>}
                        </ActivityImageContainer>

                        <ActivityDetailTableSpacing></ActivityDetailTableSpacing>


                        <h4 style={{ color:'#fe585d', fontSize: 16, marginTop: 3 }}>主辦機構</h4>
                        <table>
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: 'top' }}>
                                <ActivityDetailTableHeading>
                                {activityProviderDetail && activityProviderDetail.nameChi}
                                </ActivityDetailTableHeading>
                                <p style={{ marginBottom: 20 }}>{activityProviderDetail && activityProviderDetail.descriptionChi}</p>
                              </td>
                              <td style={{ verticalAlign: 'top' }}>
                                {providerThumbnailURL ?
                                <img
                                  style={{ marginLeft:30, marginTop: 12, width: 100, height: 100, display:'inline', borderRadius: 20, marginRight:20 }}
                                  src={providerThumbnailURL}
                                />
                                :
                                <div style={{ marginTop: 12, width: 100, height: 100, display:'inline' }}></div>
                                }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ActivityDetailTableSpacing></ActivityDetailTableSpacing>
                        <ActivityDetailTableTdMargin60></ActivityDetailTableTdMargin60>
              </ActivityDetailDiv>

            </td>
            <ActivityDetailTableRight id={"orderActivityTd"}>
              <div id={"orderActivity"} style={{ borderRadius: 20, marginTop: 50, width: '380px', height:'calc( 100vh - 150px )', backgroundColor:'white', marginLeft:40, boxShadow: "0px 3px 5px 3px rgba(0, 0, 0, 0.05)", overflow: 'scroll' }}>
                <div style={{ height:20 }}></div>
                <p style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10, marginLeft: 20 }}>請選擇活動詳情</p>
                <p style={{ color: '#777', fontSize: 16, marginLeft: 20  }}>活動選項</p>
                {displayPackageDetail && displayPackageDetail}
                <p style={{ color: '#777', fontSize: 16, marginLeft: 20, marginTop: 20  }}>選擇數量</p>
                {displayQuantityDetail && displayQuantityDetail}
                <p style={{ color: '#777', fontSize: 16, marginLeft: 20, marginTop: 20  }}>優惠碼</p>
                <table style={{ marginLeft: 20, marginRight: 20, marginTop: 8, width: 'calc( 100% - 40px )', marginLeft: "auto" }}>
                  <tbody>
                    <tr>
                      <td>
                        <input placeholder="輸入優惠碼..." style={{ borderRadius: 10, height:18, border: "solid 1px #777", fontSize: 14, padding: "10px 10px" }}></input>
                      </td>
                      <td>
                        <div style={{ cursor: 'not-allowed', backgroundColor: '#fe585d', padding: "10px 20px", color:'white', borderRadius: 100, fontSize: 14 }}>
                          <p style={{ marginLeft:18, fontWeight:'bold' }}>使用</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <center>
                  <div style={{cursor: 'not-allowed', backgroundColor: '#aaa', borderRadius: 30, width: 'calc( 100% - 40px)', color:'white', padding: "15px 0px", marginTop: 20, fontWeight:'bold'  }}>立即預約</div>
                  <div 
                    onClick={isDesktop ? toDownloadAppIOS : isIOS ? toDownloadAppIOS : toDownloadAppAndroid }
                    style={{cursor: 'pointer', backgroundColor: '#fe585d', borderRadius: 30, width: 'calc( 100% - 40px)', color:'white', padding: "15px 0px", marginTop: 10, fontWeight:'bold' }}
                  >下載 Vivablee App 報名</div>
                </center>
                <div style={{ height: 20 }}></div>
              </div>
              <div style={{ width: '380px', height: 25, marginLeft:40 }}></div>
            </ActivityDetailTableRight>
          </tr>
        </tbody>

        <ActivityDetailTableRightSmall>
        <div style={{ height:20 }}></div>
                <p style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10, marginLeft: 20 }}>請選擇活動詳情</p>
                <p style={{ color: '#777', fontSize: 16, marginLeft: 20  }}>活動選項</p>
                {displayPackageDetail && displayPackageDetail}
                <p style={{ color: '#777', fontSize: 16, marginLeft: 20, marginTop: 20  }}>選擇數量</p>
                {displayQuantityDetail && displayQuantityDetail}
                <p style={{ color: '#777', fontSize: 16, marginLeft: 20, marginTop: 20  }}>優惠碼</p>
                <table style={{ marginLeft: 20, marginRight: 20, marginTop: 8, width: 'calc( 100% - 40px )', marginLeft: "auto" }}>
                  <tbody>
                    <tr>
                      <td>
                        <input placeholder="輸入優惠碼..." style={{ borderRadius: 10, height:18, border: "solid 1px #777", fontSize: 14, padding: "10px 10px" }}></input>
                      </td>
                      <td>
                        <div style={{ cursor: 'not-allowed', backgroundColor: '#fe585d', padding: "10px 20px", color:'white', borderRadius: 100, fontSize: 14 }}>
                          <p style={{ textAlign:'center', fontWeight:'bold' }}>使用</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <center>
                  <div style={{cursor: 'not-allowed', backgroundColor: '#aaa', borderRadius: 30, width: 'calc( 100% - 40px)', color:'white', padding: "15px 0px", marginTop: 20, fontWeight:'bold'  }}>立即預約</div>
                  <div 
                    onClick={isDesktop ? toDownloadAppIOS : isIOS ? toDownloadAppIOS : toDownloadAppAndroid }
                    style={{cursor: 'pointer', backgroundColor: '#fe585d', borderRadius: 30, width: 'calc( 100% - 40px)', color:'white', padding: "15px 0px", marginTop: 10, fontWeight:'bold' }}
                  >下載 Vivablee App 報名</div>
                </center>
                <div style={{ height: 20 }}></div>
        </ActivityDetailTableRightSmall>
      </ActivityDetailContainer>
      <div ref={inputRef}></div>
      <ChillfulHomeContainer>
          <ChillfulActivityListStyle2 id="browseArticles">
            <ArticleTitleDiv>
              <ArticleTitleTop>{t('homeActivityHeaderTop')}</ArticleTitleTop>
              <ArticleTitle>{t('homeActivityHeader')}</ArticleTitle>
            </ArticleTitleDiv>

            <Margin20To40 />

            <ChillfulActivityListStyle1>
              <ChillfulActivityListStyle1TitleDiv>
                <ChillfulActivityListStyle1Title>
                  {t('homeActivitySubHeader1')}
                </ChillfulActivityListStyle1Title>
                <ChillfulActivityListStyle2Arrow
                  src={require('../images/general/arrow_right_black.png')}
                ></ChillfulActivityListStyle2Arrow>
              </ChillfulActivityListStyle1TitleDiv>
              <ChillfulMargin40 />

              {activityArray.length > 0 ? 
              <ChillfulActivityListStyle1Table>
                <tbody>
                  <tr>
                    <td>
                      <ChillfulActivityListStyle1Margin />
                    </td>
                    <ChillfulActivityListStyle1Td>
                      <ActivityCard shadow={true} id={'test1'} data={activityArray[0]} />
                    </ChillfulActivityListStyle1Td>
                    <td>
                      <ChillfulActivityListStyle1Margin2 />
                    </td>
                    <ChillfulActivityListStyle1Td>
                      <ActivityCard shadow={true} id={'test2'} data={activityArray[1]} />
                    </ChillfulActivityListStyle1Td>
                    <td>
                      <ChillfulActivityListStyle1Margin2 />
                    </td>
                    <ChillfulActivityListStyle1Td>
                      <ActivityCard shadow={true} id={'test3'} data={activityArray[2]} />
                    </ChillfulActivityListStyle1Td>
                    <td>
                      <ChillfulActivityListStyle1Margin2 />
                    </td>
                    <ChillfulActivityListStyle1Td>
                      <ActivityCard shadow={true} id={'test4'} data={activityArray[3]} />
                    </ChillfulActivityListStyle1Td>
                    <td>
                      <ChillfulActivityListStyle1Margin2 />
                    </td>
                    <ChillfulActivityListStyle1Td>
                      <ActivityCard shadow={true} id={'test5'} data={activityArray[4]} />
                    </ChillfulActivityListStyle1Td>
                    <td>
                      <ChillfulActivityListStyle1Margin />
                    </td>
                  </tr>
                </tbody>
              </ChillfulActivityListStyle1Table>
              :
              <ChillfulActivityListStyle1Table>
                <tbody>
                  <tr>
                    <td>
                      <ChillfulActivityListStyle1Margin />
                    </td>
                    <ChillfulActivityListStyle1Td>
                      <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                        style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                      />
                    </ChillfulActivityListStyle1Td>
                    <td>
                      <ChillfulActivityListStyle1Margin2 />
                    </td>
                    <ChillfulActivityListStyle1Td>
                      <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                        style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                      />
                    </ChillfulActivityListStyle1Td>
                    <td>
                      <ChillfulActivityListStyle1Margin2 />
                    </td>
                    <ChillfulActivityListStyle1Td>
                      <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                        style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                      />
                    </ChillfulActivityListStyle1Td>
                    <td>
                      <ChillfulActivityListStyle1Margin2 />
                    </td>
                    <ChillfulActivityListStyle1Td>
                      <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                        style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                      />
                    </ChillfulActivityListStyle1Td>
                    <td>
                      <ChillfulActivityListStyle1Margin2 />
                    </td>
                    <ChillfulActivityListStyle1Td>
                      <Skeleton variant="rectangular" height={320} width={350} animation="wave"
                        style={{ borderRadius: "30px", boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.05)' }} 
                      />
                    </ChillfulActivityListStyle1Td>
                    <td>
                      <ChillfulActivityListStyle1Margin />
                    </td>
                  </tr>
                </tbody>
              </ChillfulActivityListStyle1Table>
              }

            </ChillfulActivityListStyle1>

        </ChillfulActivityListStyle2>
      </ChillfulHomeContainer>
      
      <div style={{ height: 100 }}></div>

      <Footer />
    </div>
  );
}

export default ActivityFunction;

// define css class
const ActivityDetailContainer = styled.table`
  margin-left: 100px;
  margin-right: 100px;
  width: calc( 100% - 200px );
  @media only screen and (max-width: 1280px) {
    margin-left: 50px;
    margin-right: 50px;
    width: calc( 100% - 100px );
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    width: calc( 100% - 40px );
  }
`;
const ActivityBannerDiv = styled.div`
  height: 300px;
  margin-top: 50px;
  border-radius: 30px;
`;
const ActivityBanner = styled.div`
  background-image: url(${img});
  height: 300px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  border-radius: 30px;
`;
const ActivityMap = styled.div`
  margin-left: 100px;
  margin-right: 100px;
`;
const ActivityMapArrow = styled.img`
  margin-top: 4px;
  margin-left: 5px;
  margin-right: 4px;
  height: 10px;
`;
const ActivityMapText = styled.span`
  color: #ff585d; ;
`;
const ActivityDetailDiv = styled.div`
  margin-top: 40px;
`;
const ActivityDetailTable = styled.table`
  width: 100%;
`;
const ActivityDetailTableHeading = styled.h1`
  font-size: 36px;
  margin-top: 5px;
  margin-bottom: 3px;
`;
const ActivityDetailTableStart = styled.img`
  height: 16px;
  margin-right: 3px;
  margin-bottom: -1px;
`;
const ActivityDetailTableRating = styled.span`
  color: #ffc107;
`;
const ActivityDetailTableReviewDiv = styled.div`
  font-size: 16px;
`;
const ActivityDetailTableReview = styled.span`
`;
const ActivityDetailTableReview2 = styled.span`
  color: #777;
`;
const ActivityDetailTableReview3 = styled.span`
  margin-left: 5px;
  margin-right: 5px;
  color: #777;
`;
const ActivityDetailTableSpacing = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const ActivityDetailTableDiv2 = styled.div`
  line-height: 36px;
`;
const ActivityDetailTableDiv2Text = styled.span`
  margin-right: 20px;
`;
const ActivityDetailTableDiv3 = styled.div`
  line-height: 24px;
  font-size: 14px;
`;
const ActivityDetailTableDiv3Text = styled.span`
  margin-right: 10px;
`;
const ActivityDetailTableTdMargin60 = styled.div`
  width: 60px;
`;
const ActivityDetailTableTdTop = styled.td`
  vertical-align: top;
  width: 100%;
`;
const ActivityDetailTableDiscount = styled.div`
  width: 260px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
`;
const ActivityDetailTableDiscountTitle = styled.span`
  color: #ff585d;
  font-weight: 600;
`;
const ActivityDetailTableDiscountPrice = styled.span`
  font-size: 22px;
  font-weight: bold;
`;
const ActivityDetailTableDiscountText = styled.div`
  font-size: 14px;
  line-height: 24px;
`;
const ActivityDetailTableMobile = styled.div`
  width: 260px;
  padding: 28px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  margin-top: 40px;
`;
const ActivityDetailTableMobileImage = styled.img`
  height: 40px;
`;
const ActivityPackageOption = styled.div`
  padding-top: 60px;
  width: 100%;
`;
const ActivityPackageOptionChillfulImg = styled.img`
  width: 25px;
  margin-right: 10px;
  margin-bottom: -2px;
`;
const ActivityPackageOptionTitle = styled.h1`
  font-size: 30px;
  display: inline;
`;
const ActivityPackageOptionTitleMargin = styled.div`
  height: 25px;
`;
const ActivityPackageOptionTable = styled.table`
  width: 100%;
`;
const ActivityPackageOptionTableTd = styled.td`
  width: 100%;
  vertical-align: top;
`;
const ActivityPackageOptionTableDiv = styled.div`
  background-color: #f9f9f9;
  width: 100%;
`;
const ActivityPackageOptionTableDivInner = styled.div`
  padding: 30px;
`;
const ActivityPackageOptionTableSubTitle = styled.p`
  font-weight: bold;
  font-size: 20px;
`;
const ActivityPackageOptionTableSubTitle1 = styled.p`
  margin-top: 30px;
  font-size: 16px;
`;
const ActivityPackageOptionTableCalendarDiv = styled.div`
  position: absolute;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  opacity: 0;
  visibility: hide;
  transition: all 0.5s;
  z-index: -1;
`;
const ActivityPackageOptionTablePackage = styled.div`
  width: 100%;
  border: solid 2px #ddd;
  margin-top: 10px;
  cursor: pointer;
  background-color: white;
  :hover {
    border: solid 2px #ff585d;
  }
`;
const ActivityPackageOptionTablePackageInner = styled.div`
  padding: 8px;
  cursor: pointer;
`;
const ActivityPackageOptionTableQuantity = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: white;
`;
const ActivityPackageOptionTableQuantityInner = styled.div`
  padding: 20px;
`;
const ActivityPackageOptionTableQuantityDisplayInline = styled.div`
  display: inline;
`;
const ActivityPackageOptionTableQuantityPrice = styled.div`
  float: right;
`;
const ActivityPackageOptionTableQuantityPriceAmount = styled.span`
  margin-right: 20px;
  color: #ff585d;
  font-weight: bold;
`;
const ActivityPackageOptionTableQuantityPriceMinus = styled.span`
  margin-right: -1px;
  padding: 2px 10px 3px 10px;
  border: solid 1px #bbb;
  cursor: pointer;
  background-color: #f9f9f9;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
`;
const ActivityPackageOptionTableQuantityPriceQuantity = styled.input`
  margin-right: -1px;
  padding: 4px 4px 4px 4px;
  border: solid 1px #bbb;
  background-color: white;
  font-size: 14px;
  width: 50px;
  text-align: center;
  :focus {
    outline-width: 0;
  }
`;
const ActivityPackageOptionTableQuantityPricePlus = styled.span`
  padding:2px 10px 3px 10px;
  border:solid 1px #bbb;
  cursor:pointer;
  background-color:#f9f9f9
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
`;
const ActivityPackageOptionTableTotleAmountTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
`;
const ActivityPackageOptionTableTotleAmountTitleTopMargin = styled.div`
  height: 40px;
`;
const ActivityPackageOptionTableTotleAmount = styled.span`
  font-size: 20px;
  color: #ff585d;
`;
const ActivityPackageOptionTableAddToCartBtn = styled.div`
  float: right;
  margin-right: 160px;
  margin-top: -8px;
`;
const ActivityPackageOptionTableTdTop = styled.td`
  vertical-align: top;
`;
const ActivityPackageOptionTablePackageDetail = styled.div`
  width: 260px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
`;
const ActivityPackageOptionTablePackageDetailTitle = styled.span`
  color: #ff585d;
  font-weight: 600;
`;
const ActivityPackageOptionTablePackageDetailOption = styled.div`
  font-size: 14px;
  line-height: 24px;
`;
const ActivityPackageOptionTablePackageDetailOptionText1 = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
const ActivityPackageOptionTablePackageDetailOptionText2 = styled.span`
  cursor: pointer;
`;
const ActivityPackageOptionTablePackageDetailChillfulIcon = styled.img`
  width: 18px;
  margin-right: 5px;
  margin-bottom: -2px;
`;
const ActivityPackageOptionTablePackageDetailOptionHeading = styled.h1`
  font-size: 18px;
  display: inline;
`;
const ActivityPackageOptionTablePackageDetailOptionDiv = styled.div`
  font-size: 14px;
`;
const ActivityPackageOptionTablePackageDetailOptionDivText1 = styled.p`
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
`;
const ActivityPackageOptionTablePackageDetailOptionDivMargin10 = styled.span`
  margin-right: 10px;
`;
const ChillfulActivityListStyle1 = styled.div`
  overflow-x: scroll;
  height: 500px;
  padding-top: 70px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ChillfulActivityListStyle1Title = styled.span`
  font-weight: 600;
  font-size: 24px;
`;
const ChillfulActivityListStyle1TitleDiv = styled.div`
  position: absolute;
  left: 100px;
  @media only screen and (max-width: 1300px) {
    left: 50px;
  }
  @media only screen and (max-width: 900px) {
    left: 20px;
  }
`;
const ChillfulActivityListStyle1Margin = styled.div`
  width: 100px;
  @media only screen and (max-width: 1300px) {
    width: 50px;
  }
  @media only screen and (max-width: 900px) {
    width: 20px;
  }
`;
const ChillfulActivityListStyle1Margin2 = styled.div`
  width: 15px;
`;
const ChillfulActivityListStyle1Td = styled.td`
  min-width: 350px;
  max-width: 350px;
`;
const ChillfulActivityListStyle1Table = styled.table`
  width: 100%;
`;
const ChillfulActivityListStyle2Arrow = styled.img`
height: 20px;
vertical-align: middle;
margin-bottom: 6px;
margin-top: 0px;
margin-left: 5px;
alt: "arrow";
`;
const ChillfulMargin40 = styled.div`
  height: 40px;
`;
const ChillfulBannerBtn = styled.div`
  margin-top: 15px;
  background-color: #ff585d;
  padding: 10px 20px 10px 20px;
  width: 135px;
  border-radius: 50px;
  cursor: pointer;
`;
const ChillfulBannerBtnIcon = styled.img`
  width: 12px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 4px;
`;
const ChillfulBannerBtnText = styled.span`
  margin-left: 5px;
  font-size: 14px;
  color: white;
  font-weight: 600;
`;
const ChillfulTimeslotsDiv = styled.div`
  width: 100%;
  border: solid 2px #ddd;
  margin-top: 10px;
  cursor: pointer;
  background-color: white;
  :hover {
    border: solid 2px #ff585d;
  }
`;
const ChillfulTimeslotsInnerDiv = styled.div`
  padding: 8px;
  cursor: pointer;
`;

const ChillfulHomeContainer = styled.div`
  background-color: #FAFAFA;
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
  padding-top: 1px;
  margin-top: 100px;
  @media only screen and (max-width: 768px) {
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
  }
  @media only screen and (max-width: 480px) {
    border-top-right-radius: 150px;
    border-bottom-right-radius: 150px;
  }
`;

const ChillfulActivityListStyle2 = styled.div`
  @media only screen and (max-width: 1300px) {
  }
  @media only screen and (max-width: 900px) {
  }
`;

const ArticleTitle = styled.h1`
  font-size: 60px;
  color: #444;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const ArticleTitleTop = styled.h1`
  font-size: 40px;
  color: #444;
  @media only screen and (max-width: 1200px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const ArticleTitleDiv = styled.div`
  margin-left: 100px;
  margin-top: 100px;
  @media only screen and (max-width: 1300px) {
    margin-top: 80px;
    margin-left: 50px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 80px;
    margin-left: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 60px;
    margin-left: 20px;
  }
`;

const Margin20To40 = styled.div`
  height: 0px;
  margin-bottom: -20px;
  @media only screen and (max-width: 1300px) {
    margin-bottom: -30px;
  }
`;

const ActivityDetailTableRight = styled.td`
  vertical-align: top;
  @media only screen and (max-width: 1080px) {
    display: none;
  }
`;

const ActivityDetailTableRightSmall = styled.div`
  width: calc( 100% + 40px );
  margin-left: -20px;
  @media only screen and (min-width: 1079px) {
    display: none;
  }
`;

const ActivityImageContainer = styled.div`
  display:inline-block;
  white-space: nowrap;
  overflow: scroll;
  overflowY: hidden;
  width: calc( 100vw - 620px );
  @media only screen and (max-width: 1280px) {
    width: calc( 100vw - 530px );
  }
  @media only screen and (max-width: 1080px) {
    width: calc( 100vw - 100px );
  }
  @media only screen and (max-width: 768px) {
    width: calc( 100vw - 40px );
  }
`;