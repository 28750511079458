/* eslint-disable */

import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components'; // package to define css class
import {db} from '../firebase.js';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  isDesktop,
} from 'react-device-detect';
import IOSModal from '../components/IOSModal';
import AndroidModal from '../components/AndroidModal';

/**
 * display footer
 * @param {string} page
 * @return {component}
 */
function Footer() {
  function toDownloadAppIOS() {
    window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
  }
  function toDownloadAppAndroid() {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
  }
  function toInstagram() {
    window.location.href = 'https://www.instagram.com/vivablee/';
  }

  function toInfoEmail() {
    window.location.href = 'mailto:info@vivablee.com';
  }

  function toTel() {
    window.location.href = 'https://wa.me/85290901470';
  }
  function toActivities() {
    window.location.href = '/Activities';
  }
  function toMeditation() {
    window.location.href = '/Meditation';
  }
  function toCorporates() {
    window.location.href = '/Corporates';
  }
  function toMindfulness() {
    window.location.href = '/Articles';
  }
  function toAtoZ() {
    window.location.href = '/AtoZ';
  }
  const [footerLoading, setFooterLoading] = useState(true);
  const [articlesList, setArticlesList] = useState([]);

  useEffect(() => {
    db.collection('articles')
        .orderBy('order')
        .get()
        .then(function(querySnapshot) {
          querySnapshot.docs.forEach((doc) => {
            if (doc.data()['validateFlag']) {
              setArticlesList((articlesList) => [
                ...articlesList,
                {
                  article_title: doc.data()['title'],
                },
              ]);
            }
          });
        })
        .then(() => {
          setFooterLoading(false);
        });
  }, []);

  // const ref = useRef();
  const refa = useRef();
  const refi = useRef();
  // const openTooltip = () => ref.current.open();
  const openTooltipA = () => refa.current.open();
  const openTooltipI = () => refi.current.open();

  // footer content
  return (
    <div>
      {/* <PopupModal Ref={ref} Apple={ } /> */}
      <AndroidModal Ref={refa} />
      <IOSModal Ref={refi} />
      <svg
        style={{marginTop: '-7px'}}
        width="100%"
        // height="auto"
        viewBox="0 0 3815 394"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Path 6</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M-1.8189894e-12,259 C459.075022,86.3333333 973.340227,-2.27373675e-13 1542.79561,-2.27373675e-13 C2112.251,-2.27373675e-13 2869.65246,131.323801 3815,393.971403 L-1.8189894e-12,393.971403 L-1.8189894e-12,259 Z"
            id="Path-6"
            fill="#F4F2EC"
          ></path>
        </g>
      </svg>

      <ResponsiveDiv>
        <Col3>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            冥想夥伴
          </p>
          <FooterCategorySmall onClick={toMeditation}>冥想空間 - Be Mindful</FooterCategorySmall>
          <FooterCategorySmall onClick={toActivities}>特色活動 - Chill at Home</FooterCategorySmall>
          <FooterCategorySmall onClick={toCorporates}>企業服務 - Corporate</FooterCategorySmall>
          {/* <FooterCategorySmall onClick={toMindfulness}>認識靜觀 - Mindfulness</FooterCategorySmall> */}
          <FooterCategorySmall onClick={toAtoZ}>Viva百科 - Vivablee AtoZ</FooterCategorySmall>
        </Col3>
        <Col3>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            聯絡我們
          </p>
          <FooterCategorySmall onClick={toInfoEmail}>電郵: info@vivablee.com</FooterCategorySmall>
          <FooterCategorySmall onClick={toTel}>電話: +852 9090 1470</FooterCategorySmall>
          <FooterCategorySmall onClick={toInstagram}>Instagram: vivablee</FooterCategorySmall>
        </Col3>
        <Col3>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            認識靜觀
          </p>
          {
            (footerLoading) ?
              <div>
                <Skeleton height={30} width={'60%'} variant='text' />
                <Skeleton height={30} width={'60%'} variant='text' />
                <Skeleton height={30} width={'60%'} variant='text' />
                <Skeleton height={30} width={'60%'} variant='text' />
              </div> :
              articlesList.slice(0, 4)
                  .map((item, i) => {
                    return (
                      <Link
                        to={{
                          pathname: '/Articles/' + item.article_title,
                        }}
                        style={{textDecoration: 'none', color: 'black'}}
                        key={i}
                      >
                        <FooterCategorySmall style={{marginRight: 60}}>
                          {item.article_title}
                        </FooterCategorySmall>
                      </Link>
                    );
                  })
          }
          {/* <FooterCategorySmall style={{ marginRight: 60 }} onClick={() => toArticlesId("建立靜觀生活方式")}>
            建立靜觀生活方式
          </FooterCategorySmall>
          <FooterCategorySmall style={{ marginRight: 60 }} onClick={() => toArticlesId("職業枯竭 - 你在工作期間有被耗盡身心的感覺嗎？")}>
            職業枯竭 - 你在工作期間有被耗盡身心的感覺嗎？
          </FooterCategorySmall>
          <FooterCategorySmall style={{ marginRight: 60 }} onClick={() => toArticlesId("語言藝術")}>
            語言藝術
          </FooterCategorySmall>
          <FooterCategorySmall style={{ marginRight: 60 }} onClick={() => toArticlesId("為什麼很睏仍要通宵追劇和上網")}>
            為什麼很睏仍要通宵追劇和上網
          </FooterCategorySmall> */}
        </Col3>
        <Col3>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            下載 Vivablee
          </p>

          <img
            // onClick={openTooltipI}
            onClick={isDesktop ? openTooltipI : toDownloadAppIOS}
            style={{width: '60%', cursor: 'pointer'}}
            src={require('../images/general/app_store.png')}
            alt="iosQRcode"
          ></img>
          <img
            // onClick={openTooltipA}
            onClick={isDesktop ? openTooltipA : toDownloadAppAndroid}
            style={{width: '60%', cursor: 'pointer'}}
            src={require('../images/general/google_play.png')}
            alt="androidQRcode"
          ></img>

        </Col3>
      </ResponsiveDiv>

      <ResponsiveDiv1024>
        <Col4>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            冥想夥伴
          </p>
          <FooterCategorySmall onClick={toMeditation}>冥想空間 - Be Mindful</FooterCategorySmall>
          <FooterCategorySmall onClick={toActivities}>特色活動 - Chill at Home</FooterCategorySmall>
          <FooterCategorySmall onClick={toCorporates}>企業服務 - Corporate</FooterCategorySmall>
          {/* <FooterCategorySmall onClick={toMindfulness}>認識靜觀 - Mindfulness</FooterCategorySmall> */}
          <FooterCategorySmall onClick={toAtoZ}>Viva百科 - Vivablee AtoZ</FooterCategorySmall>
        </Col4>
        <Col4>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            聯絡我們
          </p>
          <FooterCategorySmall onClick={toInfoEmail}>電郵: info@vivablee.com</FooterCategorySmall>
          <FooterCategorySmall onClick={toTel}>電話: +852 9090 1470</FooterCategorySmall>
          <FooterCategorySmall onClick={toInstagram}>Instagram: vivablee</FooterCategorySmall>
        </Col4>
        <Col4>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            下載 Vivablee
          </p>
          <img
            // onClick={openTooltipI}
            onClick={isDesktop ? openTooltipI : toDownloadAppIOS}
            style={{width: '60%', cursor: 'pointer'}}
            src={require('../images/general/app_store.png')}
            alt="iosQRcode"
          ></img>
          <img
            // onClick={openTooltipA}
            onClick={isDesktop ? openTooltipA : toDownloadAppAndroid}
            style={{width: '60%', cursor: 'pointer'}}
            src={require('../images/general/google_play.png')}
            alt="androidQRcode"
          ></img>
        </Col4>
        <div style={{height: '20px'}}></div>
        <Col8>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            認識靜觀
          </p>
          {
            (footerLoading) ?
              <div>
                <Skeleton height={30} width={'60%'} variant='text' />
                <Skeleton height={30} width={'60%'} variant='text' />
                <Skeleton height={30} width={'60%'} variant='text' />
                <Skeleton height={30} width={'60%'} variant='text' />
              </div> :
              articlesList.slice(0, 4)
                  .map((item, i) => {
                    return (
                      <Link
                        to={{
                          pathname: '/Articles/' + item.article_title,
                        }}
                        style={{textDecoration: 'none', color: 'black'}}
                        key={i}
                      >
                        <FooterCategorySmall style={{marginRight: 60}}>
                          {item.article_title}
                        </FooterCategorySmall>
                      </Link>
                    );
                  })
          }
          {/* <FooterCategorySmall onClick={() => toArticlesId("建立靜觀生活方式")}>
            建立靜觀生活方式
          </FooterCategorySmall>
          <FooterCategorySmall onClick={() => toArticlesId("職業枯竭 - 你在工作期間有被耗盡身心的感覺嗎？")}>
            職業枯竭 - 你在工作期間有被耗盡身心的感覺嗎？
          </FooterCategorySmall>
          <FooterCategorySmall onClick={() => toArticlesId("語言藝術")}>語言藝術</FooterCategorySmall>
          <FooterCategorySmall onClick={() => toArticlesId("為什麼很睏仍要通宵追劇和上網")}>
            為什麼很睏仍要通宵追劇和上網
          </FooterCategorySmall> */}
        </Col8>
      </ResponsiveDiv1024>

      <ResponsiveDiv698>
        <Col6>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            冥想夥伴
          </p>
          <FooterCategorySmall style={{marginRight: 20}} onClick={toMeditation}>
            冥想空間 - Be Mindful
          </FooterCategorySmall>
          <FooterCategorySmall style={{marginRight: 20}} onClick={toActivities}>
            特色活動 - Chill at Home
          </FooterCategorySmall>
          <FooterCategorySmall style={{marginRight: 20}} onClick={toCorporates}>
            企業服務 - Corporate
          </FooterCategorySmall>
          {/* <FooterCategorySmall style={{marginRight: 20}} onClick={toMindfulness}>
            認識靜觀 - Mindfulness
          </FooterCategorySmall> */}
          <FooterCategorySmall style={{marginRight: 20}} onClick={toAtoZ}>
            Viva百科 - Vivablee AtoZ
          </FooterCategorySmall>
        </Col6>
        <Col6>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            聯絡我們
          </p>
          <FooterCategorySmall onClick={toInfoEmail}>電郵: info@vivablee.com</FooterCategorySmall>
          <FooterCategorySmall onClick={toTel}>電話: +852 9090 1470</FooterCategorySmall>
          <FooterCategorySmall onClick={toInstagram}>Instagram: vivablee</FooterCategorySmall>
        </Col6>
        <div style={{height: '20px'}}></div>
        <Col6>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#444', marginBottom: '20px'}}>
            認識靜觀
          </p>
          {
            (footerLoading) ?
              <div>
                <Skeleton height={30} width={'60%'} variant='text' />
                <Skeleton height={30} width={'60%'} variant='text' />
                <Skeleton height={30} width={'60%'} variant='text' />
                <Skeleton height={30} width={'60%'} variant='text' />
              </div> :
              articlesList.slice(0, 4)
                  .map((item, i) => {
                    return (
                      <Link
                        to={{
                          pathname: '/Articles/' + item.article_title,
                        }}
                        style={{textDecoration: 'none', color: 'black'}}
                        key={i}
                      >
                        <FooterCategorySmall style={{marginRight: 60}}>
                          {item.article_title}
                        </FooterCategorySmall>
                      </Link>
                    );
                  })
          }
          {/* <FooterCategorySmall style={{ marginRight: 20 }} onClick={() => toArticlesId("建立靜觀生活方式")}>
            建立靜觀生活方式
          </FooterCategorySmall>
          <FooterCategorySmall style={{ marginRight: 20 }} onClick={() => toArticlesId("職業枯竭 - 你在工作期間有被耗盡身心的感覺嗎？")}>
            職業枯竭 - 你在工作期間有被耗盡身心的感覺嗎？
          </FooterCategorySmall>
          <FooterCategorySmall style={{ marginRight: 20 }} onClick={() => toArticlesId("語言藝術")}>
            語言藝術
          </FooterCategorySmall>
          <FooterCategorySmall style={{ marginRight: 20 }} onClick={() => toArticlesId("為什麼很睏仍要通宵追劇和上網")}>
            為什麼很睏仍要通宵追劇和上網
          </FooterCategorySmall> */}
        </Col6>
        <Col6>
          <img
            onClick={isDesktop ? openTooltipI : toDownloadAppIOS}
            // onClick={openTooltipI}
            style={{width: '60%', cursor: 'pointer'}}
            src={require('../images/general/app_store.png')}
            alt="iosQRcode"
          ></img>
          <img
            onClick={isDesktop ? openTooltipA : toDownloadAppAndroid}
            // onClick={openTooltipA}
            style={{width: '60%', cursor: 'pointer'}}
            src={require('../images/general/google_play.png')}
            alt="androidQRcode"
          ></img>
        </Col6>
      </ResponsiveDiv698>
    </div>
  );
}

export default Footer;

const FooterCategorySmall = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #444;
  cursor: pointer;
`;
const Col3 = styled.div`
  width: 25%;
  display: inline-block;
  vertical-align: top;
`;
const Col4 = styled.div`
  width: 33.33%;
  display: inline-block;
  vertical-align: top;
`;
/*
const Col5 = styled.div`
  width: 41.66%;
  display: inline-block;
  vertical-align: top;
`;
*/
const Col6 = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
`;
/*
const Col7 = styled.div`
  width: 58.33%;
  display: inline-block;
  vertical-align: top;
`;
*/
const Col8 = styled.div`
  width: 66.66%;
  display: inline-block;
  vertical-align: top;
`;
/*
const Col9 = styled.div`
  width: 75%;
  display: inline-block;
  vertical-align: top;
`;
const Col10 = styled.div`
  width: 83.33%;
  display: inline-block;
  vertical-align: top;
`;
const Col11 = styled.div`
  width: 91.66%;
  display: inline-block;
  vertical-align: top;
`;
const Col12 = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
`;
*/

const ResponsiveDiv = styled.div`
  background-color: #f4f2ec;
  margin-top: -7px;
  padding: 0px 150px 100px 150px;
  @media only screen and (max-width: 1200px) {
    padding: 0px 80px 100px 80px;
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const ResponsiveDiv1024 = styled.div`
  background-color: #f4f2ec;
  margin-top: -7px;
  padding: 0px 150px 100px 150px;
  @media only screen and (min-width: 1025px) {
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    padding: 0px 80px 100px 80px;
  }
  @media only screen and (max-width: 768px) {
    padding: 0px 20px 100px 20px;
  }
  @media only screen and (max-width: 698px) {
    display: none;
  }
`;

const ResponsiveDiv698 = styled.div`
  background-color: #f4f2ec;
  margin-top: -7px;
  padding: 0px 150px 100px 150px;
  @media only screen and (min-width: 699px) {
    display: none;
  }
  @media only screen and (min-width: 1025px) {
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    padding: 0px 80px 100px 80px;
  }
  @media only screen and (max-width: 768px) {
    padding: 0px 20px 100px 20px;
  }
`;
