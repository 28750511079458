/* eslint-disable */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      homeHeaderTop: 'Vivablee:',
      homeHeader: 'Mental wellness\ncompanion ',
      homeSubHeader: 'Together, live in the moment',
      homeBtn: 'Explore',
      homeToInstagram: 'Meet Viva & Friends',
      chillAtHomeHeaderTop: 'Chill At Home:',
      chillAtHomeHeader: 'Explore special\nlocal activities',
      chillAtHomeSubHeader:
        'An one-stop online experience for you to explore every possibility at home',
      chillAtHomeBtn: 'Explore',
      chillAtHomeToInstagram: 'Meet Viva & Friends',
      beMindfulHeaderTop: 'Be Mindful:',
      beMindfulHeader: 'Indulge in your\nmindful space',
      beMindfulSubHeader:
        'Embark on your gamified mindfulness journey with Cantonese meditation soundtracks',
      beMindfulBtn: 'Explore',
      beMindfulToInstagram: 'Meet Viva & Friends',
      homeActivityHeaderTop: 'Chill At Home:',
      homeActivityHeader: 'Online Activity',
      homeActivitySubHeader1: 'Featured Activities',
      homeActivitySubHeader2: 'New Activities',
      homeMindfulHeader: 'Your Mindful Space ',
      homeMindfulSubHeader:
        'Experience mindfulness anytime and anywhere with our gamified mindfulness journeys and vast Cantonese meditation soundtracks library. ',
      homeMClosingHeader: 'Together,\nlive in the moment',
      homeMClosingBtn: 'About Us',
      homeDownloadAppHeader: 'Join millions getting more mindful with Vivablee App',
      homeDownloadAppDownload: 'Try Vivablee for free',
      homeDownloadAppDownloadBtn: 'Try it for free',
      homeDownloadAppDownloadNote:
        'After your free trial, the annual subscription is HK$38.00 and automatically renews each year. If you subscribe before your free trial ends, the rest of your free trial period will be forfeited as soon as your purchase is confirmed.',

      meditationBannerCategory: 'Journey',
      meditationBannerTitle: 'Session 11: Mindful Walking',
      meditationBannerDescription:
        'Through mindful walking, you will learn to appreciate every step taken.',
      meditationBannerGuidedMeditation: 'Guided meditation',
      meditationBannerTime: '5 minutes',

      menuActivities: 'Activities',
      menuMindful: 'Meditation',
      menuCorporate: 'Corporate',
      menuArticles: 'Articles',
      menuAboutUs: 'About Us',
      menuDownloadApp: 'Try it for free',
    },
  },
  zh_HK: {
    translation: {
      homeHeaderTop: 'Vivablee:',
      homeHeader: '你的\n身心健康夥伴 ',
      homeSubHeader: '活在當下，享受身邊一切美好',
      homeBtn: '探索更多',
      homeToInstagram: '認識 Viva 仔',
      chillAtHomeHeaderTop: 'Chill At Home:',
      chillAtHomeHeader: '探索\n本土特色活動',
      chillAtHomeSubHeader: '一站式線上體驗，在家中發掘無限可能',
      chillAtHomeBtn: '探索更多',
      chillAtHomeToInstagram: '認識 Viva 仔',
      beMindfulHeaderTop: 'Be Mindful:',
      beMindfulHeader: '享受你的\n專屬冥想空間',
      beMindfulSubHeader: '遊戲化介面搭配廣東話冥想錄音，開展嶄新靜觀旅程',
      beMindfulBtn: '探索更多',
      beMindfulToInstagram: '認識 Viva 仔',
      homeActivityHeaderTop: 'Chill At Home:',
      homeActivityHeader: '線上休閒活動體驗',
      homeActivitySubHeader1: '熱門體驗',
      homeActivitySubHeader2: '精選活動',
      homeMindfulHeader: '你的專屬冥想空間',
      homeMindfulSubHeader:
        '遊戲化的冥想旅程及圍繞日常生活6大範疇的廣東話冥想錄音，讓你無時無刻都能體驗靜觀。',
      homeMClosingHeader: '活在當下，\n享受身邊一切美好',
      homeMClosingBtn: '關於我們',
      homeDownloadAppHeader: '下載 Vivablee 應用程式，\n享受身邊一切美好',
      homeDownloadAppDownload: '立即下載 Vivablee 應用程式',
      homeDownloadAppDownloadBtn: '立即下載',
      homeDownloadAppDownloadNote:
        '每月的訂閱費用為 HK $ 8.00，到期自動更新。如訂閱一年，即可以優惠價 HK $ 38.00 訂閱。',

      meditationBannerCategory: '旅程',
      meditationBannerTitle: '第11節：靜觀步行',
      meditationBannerDescription:
        '我們每次步行的經歷都是獨得的，通過這個練習，我們可以學會關注步行時踏出的每一步。',
      meditationBannerGuidedMeditation: '冥想練習',
      meditationBannerTime: '5 分鐘',

      homePage: '主頁',

      menuActivities: '特色活動',
      menuMindful: '冥想空間',
      menuCorporate: '企業服務',
      menuArticles: '認識靜觀',
      menuAtoZ: 'Viva百科',
      menuAboutUs: '關於我們',
      menuDownloadApp: '立即下載',

      moreArticles: '更多精彩文章',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'zh_HK',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
