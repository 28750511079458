/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
import img from '../images/mindful/walk.jpg';
import { db } from '../firebase.js';
import 'react-calendar/dist/Calendar.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PopupModal from '../components/PopupModal';
import { Amplify, Hub } from "@aws-amplify/core";
import { useTranslation } from 'react-i18next';
import {
  isDesktop,
  isIOS
} from "react-device-detect";
import { JourneyCheckpoint, JourneyStage, Journey } from '../models';
import { DataStore, Storage } from 'aws-amplify';
import Skeleton from '@material-ui/lab/Skeleton';

function Meditation() {
  const { t, i18n } = useTranslation();

  const [chillfulBannerTitle, setChillfulBannerTitle] = useState('');
  const [chillfulBannerDescription, setChillfulBannerDescription] = useState('');
  const [guidedMeditationPopup, setGuidedMeditationPopup] = useState([]);
  const [journeyBannerInfo, setJourneyBannerInfo] = useState();

  const [isPopup, setIsPopup] = useState(false);

  const chillfulActivityCategoryId = '';

  function showMeditation(meditation_id) {
    $('#playVideoDiv').css('display', 'block');
    $('body').css('overflow', 'hidden');
    db.collection('mindfulGuidedMeditation')
      .doc(meditation_id)
      .get()
      .then((doc) => {
        setGuidedMeditationPopup((guidedMeditationPopup) => [
          {
            audio: doc.data()['audio'],
            title: doc.data()['title'],
            category: doc.data()['category'],
            description: doc.data()['description'],
          },
        ]);
      });
  }

  useEffect(() => {
    $('#hideVideoBtn').click(function () {
      $('#playVideoDiv').css('display', 'none');
      $('body').css('overflow', 'scroll');
    });
  }, []);

  const ref = useRef();
  const openTooltip = () => ref.current.open();

  function toDownloadAppIOS() {
    window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
  }
  function toDownloadAppAndroid() {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
  }

  useEffect(() => {
    getJourneyBanner();
  },[])

  const getJourneyBanner = async () => {
    const journeyCheckpointBanner = await DataStore.query(JourneyCheckpoint, c => c.nameChi("eq", "應對分心"));
    const journeyStageBanner = await DataStore.query(JourneyStage, journeyCheckpointBanner[0].journeystageID);
    const journeyBanner = await DataStore.query(Journey, journeyStageBanner.journeyID);

    const audioDetail = JSON.parse(
      journeyCheckpointBanner[0].content[0]
    );

    if(journeyBanner.imagesLarge[0] != undefined && audioDetail.audioFemale != undefined){

        const url = getAudioURL(audioDetail.audioFemale).then((response)=>{

          const urlImage = getImageUrl(journeyBanner.imagesLarge[0]).then((responseImage)=>{
            setJourneyBannerInfo({
              'titleChi': journeyCheckpointBanner[0].nameChi,
              'descriptionChi': journeyBanner.descriptionChi,
              'duration': "",
              'journeyTitleChi': journeyBanner.nameChi,
              'imageURL': responseImage,
              'duration': audioDetail.durationFemale,
              'audio': response,
            })
          })

        })

    }
  }

  const getImageUrl = async (image) => {
    const url = await Storage.get(image)
    return url;
  };

  const getAudioURL = async (str) => {
    const url = await Storage.get(str);
    return url;
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        <PopupModal Ref={ref} />
        <div
          id="playVideoDiv"
          style={{
            display: 'none',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            zIndex: '200',
          }}
        >
          <div id="hideVideoBtn" style={{ display: 'table', margin: '80px auto 50px auto' }}>
            <p
              style={{
                color: '#555',
                backgroundColor: '#fafafa',
                padding: '5px 14px 6px 14px',
                borderRadius: '50px',
                fontSize: '24px',
                cursor: 'pointer',
              }}
            >
              ⨉
          </p>
          </div>

          <MeditationPopupTable cellspacing="0" cellpadding="0">
            <colgroup>
              <col style={{ width: '50%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <div
                    style={{ borderRadius: '20px', width: 'calc( 100% )', backgroundColor: 'white' }}
                  >
                    <div style={{ padding: '50px' }}>
                      <ChillfulActivityListIcon
                        src={require('../images/general/mindful_icon_original.png')}
                      ></ChillfulActivityListIcon>
                      {guidedMeditationPopup.map((data) => (
                        <ChillfulActivityListStyle2ClassBottomText1>
                          {data.category}
                        </ChillfulActivityListStyle2ClassBottomText1>
                      ))}
                      {guidedMeditationPopup.map((data) => (
                        <p style={{ fontSize: '28px', fontWeight: '600', marginTop: '3px' }}>
                          {data.title}
                        </p>
                      ))}
                      {guidedMeditationPopup.map((data) => (
                        <BannerAudio style={{ width: '90%' }} controls>
                          <source src={data.audio} type="audio/ogg" />
                          <source src={data.audio} type="audio/mpeg" />
                        </BannerAudio>
                      ))}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </MeditationPopupTable>
        </div>

        <Menu page={'meditation'} />

        {/* Be Chillful top banner */}
        {journeyBannerInfo &&
          <ChillfulBanner style={{ backgroundImage: 'url(' + journeyBannerInfo.imageURL + ')' }}>
            <ChillfulMargin120 />

            <ChillfulBannerText>
              <ChillfulBannerIcon
                style={{ marginBottom: '-3px' }}
                src={require('../images/general/mindful_icon_original.png')}
              ></ChillfulBannerIcon>
              <ChillfulBannerText1>{t('meditationBannerCategory')}</ChillfulBannerText1>
              <ChillfulBannerText2>{journeyBannerInfo.titleChi}</ChillfulBannerText2>
              <ChillfulBannerText3>{journeyBannerInfo.descriptionChi}</ChillfulBannerText3>
              <ChillfulBannerText4>
                {t('meditationBannerGuidedMeditation')} ● {Math.round(journeyBannerInfo.duration/60000)} 分鐘
              </ChillfulBannerText4>
              <BannerAudio controls>
                <source
                  src={journeyBannerInfo.audio}
                  type="audio/ogg"
                />
                <source
                  src={journeyBannerInfo.audio}
                  type="audio/mpeg"
                />
              </BannerAudio>
            </ChillfulBannerText>
          </ChillfulBanner>
        // :
        //   <ChillfulBannerSkeleton>
        //     <Skeleton width={'100%'} height={700} animation="wave"  style={{backgroundColor:"green"}} />
        //   </ChillfulBannerSkeleton>
        }

        {/*
          <MeditationSearchBox />
        */}

        <div id="demoMeditationScroll" style={{ marginTop: '80px' }} />

        <MindfulDivHeader>遊戲化的冥想旅程</MindfulDivHeader>
        <MindfulDivSubHeader>
          透過靜觀旅程，體驗不同的冥想練習，並可以在建立恆常練習的習慣的同時，獲得不同的獎賞。
      </MindfulDivSubHeader>

        <center>
          <AppDemoDiv>
            <div style={{ marginTop: '100px' }}>
              <AppDemoImage
                src={require('../images/mindful/gamification_1_zh_HK.png')}
              ></AppDemoImage>
              <AppDemoImage
                src={require('../images/mindful/gamification_2_zh_HK.png')}
              ></AppDemoImage>
              <AppDemoImage2
                src={require('../images/mindful/gamification_3_zh_HK.png')}
              ></AppDemoImage2>
            </div>
            <div style={{ marginTop: '80px', position: 'relative' }}>
              <div
                style={{
                  left: '50%',
                  marginRight: '-50%',
                  position: 'absolute',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <BannerBtn onClick={isDesktop ? openTooltip : (isIOS ? toDownloadAppIOS : toDownloadAppAndroid)}>{t('homeDownloadAppDownloadBtn')}</BannerBtn>
              </div>
            </div>
          </AppDemoDiv>
        </center>

        <ChillfulBody>
          <svg
            style={{ marginTop: '100px', marginBottom: '-7px' }}
            width="100%"
            //height="auto"
            viewBox="0 0 3840 260"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Path</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path
                d="M-9.09494702e-13,0.5 L-9.09494702e-13,259.5 C543.289571,86.8333333 1184.99624,0.5 1925.12,0.5 C2499.39149,0.5 3137.68482,86.8333333 3840,259.5 L3840,0.5 L-9.09494702e-13,0.5 Z"
                id="Path"
                fill="#f3f3f3"
                transform="translate(1920.000000, 130.000000) scale(1, -1) translate(-1920.000000, -130.000000) "
              ></path>
            </g>
          </svg>

          <ChillfulActivityListStyle1 style={{ backgroundColor: '#f3f3f3' }}>
            <ChillfulActivityListStyle1TitleDiv>
              <AmbientMusicTitle>
                多樣化的廣東話冥想練習庫，
              <br />
              圍繞日常生活6大範疇，時刻深化你的靜觀體驗。
            </AmbientMusicTitle>
            </ChillfulActivityListStyle1TitleDiv>
            <AmbientMusicMarginDiv></AmbientMusicMarginDiv>

            <Carousel
              style={{ zIndex: 100 }}
              emulateTouch={true}
              interval={3000}
              autoPlay={true}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                return (
                  // this is styled component.
                  <div
                    selected={isSelected}
                    onClick={onClickHandler}
                    style={{
                      marginLeft: 8,
                      marginRight: 8,
                      cursor: 'pointer',
                      height: 10,
                      width: 10,
                      borderRadius: 20,
                      backgroundColor: isSelected ? '#FF9700' : 'white',
                      display: 'inline-block',
                    }}
                  ></div>
                );
              }}
            >
              <div>
                <Div50></Div50>
                <center>
                  <TopImageDiv>
                    <TopImage src={require('../images/mindful/dailyRoutine_zh_HK.png')}></TopImage>
                  </TopImageDiv>
                  <TopImageDiv2>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>起床</span>
                    <p style={{ marginBottom: 20 }}>早晨醒來，以冥想開始平靜清新的一天</p>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>旅途</span>
                    <p style={{ marginBottom: 20 }}>
                      火車和巴士的旅程已成為你旅鬆的一部分，並為您的工作做好了準備
                  </p>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>專注</span>
                    <p style={{ marginBottom: 20 }}>通過短暫的冥想練習，增強自己的注意力</p>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>放鬆</span>
                    <p style={{ marginBottom: 20 }}>
                      在工作或上學後的一天，消除壓力，享受靜觀帶來的放鬆感覺
                  </p>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>生活</span>
                    <p style={{ marginBottom: 20 }}>
                      與同伴進行冥想練習以達至感受當下的生活方式(例如，靜觀飲食，減輕壓力的系列)
                  </p>
                    <ChillfulBannerIcon
                      style={{ marginBottom: '-3px', width: 20, height: 20, marginRight: 5 }}
                      src={require('../images/general/mindful_icon_original.png')}
                    ></ChillfulBannerIcon>
                    <span style={{ fontWeight: '600' }}>睡眠</span>
                    <p style={{ marginBottom: 20 }}>
                      失眠或想改善睡眠質素？冥想及睡眠音樂肯定會有所幫助
                  </p>
                    <div style={{ height: 30 }}></div>
                  </TopImageDiv2>
                </center>
              </div>
              <div>
                <center>
                  <AppDemoDiv style={{ marginTop: -100 }}>
                    <div style={{ marginTop: '100px' }}>
                      <AppDemoImage4
                        src={require('../images/mindful/dailyRoutine_1_zh_HK.png')}
                      ></AppDemoImage4>
                      <AppDemoImage3
                        src={require('../images/mindful/dailyRoutine_2_zh_HK.png')}
                      ></AppDemoImage3>
                      <AppDemoImage2
                        src={require('../images/mindful/dailyRoutine_3_zh_HK.png')}
                      ></AppDemoImage2>
                    </div>
                  </AppDemoDiv>
                  <div style={{ height: 120 }}></div>
                </center>
              </div>
            </Carousel>
          </ChillfulActivityListStyle1>

          <div style={{ height: 100, backgroundColor: '#F3F3F3' }}></div>
        </ChillfulBody>

        <svg
          style={{ backgroundColor: 'white', marginTop: '-80px', zIndex: '3', position: 'relative' }}
          width="100%"
          //height="auto"
          viewBox="0 0 3840 259"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M0,0 C539.876237,172.666667 1179.87624,259 1920,259 C2494.27149,259 3134.27149,172.666667 3840,0 L0,0 Z"
              id="Path"
              fill="#f3f3f3"
            ></path>
          </g>
        </svg>

        <Footer />
      </div>
    </div>
  );
}

export default Meditation;

const ChillfulBanner = styled.div`
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 100px;
  height: 550px;
  background-size: cover;
  background-position: center;
  // background-image: url(${img});
  border-radius: 30px;
  @media only screen and (max-width: 1200px) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const ChillfulBannerSkeleton = styled.div`
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 100px;
  height: 550px;
  background-color: red;
  border-radius: 30px;
  @media only screen and (max-width: 1200px) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const BannerAudio = styled.audio`
  margin-top: 30px;
  width: 500px;
  @media only screen and (max-width: 1025px) {
    width: 90%;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  @media only screen and (max-width: 480px) {
    width: 90%;
  }
`;

const ChillfulMargin160 = styled.div`
  height: 160px;
`;
const ChillfulMargin120 = styled.div`
  height: 120px;
`;
const ChillfulBannerText = styled.div`
  margin-left: 180px;
  margin-right: 180px;
  color: white;
  transition: all 0.5s;
  @media only screen and (max-width: 1100px) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media only screen and (max-width: 800px) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;
const ChillfulBannerText1 = styled.span`
  margin-left: 10px;
  color: #ff9700;
  font-weight: 800;
  font-size: 24px;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    font-size: 18px;
  }
`;
const ChillfulBannerText2 = styled.p`
  font-weight: 600;
  font-size: 42px;
  margin-top: 10px;
  transition: all 0.5s;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 800px) {
    font-size: 36px;
  }
`;
const ChillfulBannerText3 = styled.p`
  font-weight: 500;
  font-size: 24px;
  margin-top: 5px;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
  }
`;
const ChillfulBannerText4 = styled.p`
  font-weight: 500;
  font-size: 20px;
  margin-top: 15px;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
`;
const ChillfulBannerIcon = styled.img`
  height: 24px;
  transition: all 0.5s;
  @media only screen and (max-width: 800px) {
    height: 18px;
  }
`;
const ChillfulBody = styled.div`
  width: 100%;
`;
const ChillfulActivityListIcon = styled.img`
  height: 12px;
  margin-right: 2px;
  margin-bottom: -1px;
  display: inline;
`;

const ChillfulActivityListStyle2ClassBottomText1 = styled.div`
  font-size: 14px;
  color: #ff9700;
  display: inline;
  margin-left: 3px;
  font-weight: 700;
  text-transform: uppercase;
`;
const ChillfulActivityListStyle1 = styled.div`
  padding-top: 70px;
`;
const ChillfulActivityListStyle1Title = styled.span`
  font-size: 18px;
`;
const ChillfulActivityListStyle1TitleDiv = styled.div`
  margin-left: 150px;
  margin-right: 150px;
  @media only screen and (max-width: 1024px) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 70px;
    margin-right: 70px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
const AmbientMusicMarginDiv = styled.div`
  height: 60px;
  @media only screen and (max-width: 1024px) {
    height: 40px;
  }
  @media only screen and (max-width: 768px) {
    height: 30px;
  }
  @media only screen and (max-width: 480px) {
    height: 20px;
  }
`;
const AmbientMusicTitle = styled.h1`
  color: #333;
  font-weight: 600;
  font-size: 30px;
  margin-left: 0px;
  @media only screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

const MeditationPopupTable = styled.table`
  width: 1000px;
  margin: 0px auto;
  border-spacing: 0px;
  border-collapse: 0px;
  @media only screen and (max-width: 1200px) {
    width: 900px;
  }
  @media only screen and (max-width: 1024px) {
    width: 700px;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 40px);
  }
  @media only screen and (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

const AppDemoTitle = styled.h1`
  font-size: 60px;
  white-space: pre-line;
  @media only screen and (max-width: 1200px) {
    font-size: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const AppDemoDiv = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: -60px;
  @media only screen and (max-width: 1200px) {
    margin-left: 80px;
    margin-right: 80px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const AppDemoImage = styled.img`
  width: 250px !important;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 172px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    width: 150px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const AppDemoImage4 = styled.img`
  width: 250px !important;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 172px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    width: 200px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const AppDemoImage3 = styled.img`
  width: 250px !important;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 172px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    display: none !important;
  }
`;

const AppDemoImage2 = styled.img`
  width: 250px !important;
  margin-left: 35px;
  margin-right: 35px;
  border-radius: 27px;
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 1024px) {
    width: 200px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    display: none !important;
    width: 200px;
  }
  @media only screen and (max-width: 480px) {
    display: none !important;
    width: 200px;
  }
`;

const BannerBtn = styled.button`
  padding: 20px 35px;
  color: white;
  border-radius: 50px;
  border-style: none;
  background-color: #ff9700;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-top: 20px;
  @media only screen and (max-width: 1024px) {
    margin-top: 20px;
    font-size: 16px;
    padding: 20px 35px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    font-size: 16px;
    padding: 12px 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 14px;
    padding: 10px 16px;
  }
`;

const MindfulDivHeader = styled.h1`
  text-align: center;
  font-size: 45px;
  margin-bottom: 10px;
  margin-top: 160px;
  @media only screen and (max-width: 1024px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const MindfulDivSubHeader = styled.p`
  text-align: center;
  font-size: 24px;
  margin-bottom: -50px;
  margin-left: 15vw;
  margin-right: 15vw;
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
    margin-bottom: -70px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const TopImageDiv = styled.div`
  height: 40px;
  width: 1000px;
  boxshadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 1200px) {
    height: 40px;
    width: 1000px;
  }
  @media only screen and (max-width: 980px) {
    height: 320px;
    width: 800px;
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 650px) {
    display: none;
  }
`;

const TopImageDiv2 = styled.div`
  display: none;
  text-align: left;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: -50px;
  @media only screen and (max-width: 650px) {
    display: block;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const TopImage = styled.img`
  height: 400px;
  @media only screen and (max-width: 1200px) {
    height: 400px;
  }
  @media only screen and (max-width: 980px) {
    height: 320px;
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
  }
`;

const Div50 = styled.div`
  height: 50px;
  @media only screen and (max-width: 1200px) {
    height: 0px;
  }
  @media only screen and (max-width: 980px) {
    height: 50px;
  }
  @media only screen and (max-width: 768px) {
    height: 20px;
  }
  @media only screen and (max-width: 680px) {
    height: 50px;
  }
`;
