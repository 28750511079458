/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'; // package to define css class
import $ from 'jquery'; // package to run jQuery
import PopupModal from './PopupModal';
import { Storage } from 'aws-amplify';

import {
  isDesktop,
  isIOS
} from "react-device-detect";

// define js function
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
async function activityCard_mouseOver(id, activity_id) {
  if(isDesktop){
    $('#activityCard_' + id + '_' + activity_id).css('marginRight', '70px');
    $('#activityCard_' + id + '_' + activity_id).css('marginTop', '-80px');
    $('#activityCard_' + id + '_' + activity_id).css('width', '400px');
    $('#activityCard_' + id + '_' + activity_id).css('marginLeft', '-25px');
    $('#activityCard_' + id + '_' + activity_id).css('boxShadow', '0px 0px 12px 5px rgba(0, 0, 0, 0.2)');
    $('#activityCard_' + id + '_' + activity_id).css('zIndex', '10');
    $('#activityCard_' + id + '_' + activity_id)
      .find('.bottomContainer')
      .css('height', '230px');
    $('#activityCard_' + id + '_' + activity_id)
      .find('.activity_card_btn')
      .css('display', 'block');
    await sleep(300);
    if ($('#activityCard_' + id + '_' + activity_id + ':hover').length > 0) {
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_description')
        .css('opacity', '1');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_description')
        .css('height', 'auto');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_description')
        .css('visibility', 'visible');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_price')
        .css('opacity', '1');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_price')
        .css('height', 'auto');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_price')
        .css('visibility', 'visible');
    }
  }
}

async function activityCard_mouseOut(id, activity_id) {
  if(isDesktop){
    if ($('#activityCard_' + id + '_' + activity_id + ':hover').length === 0) {
      $('#activityCard_' + id + '_' + activity_id).css('marginRight', '0px');
      $('#activityCard_' + id + '_' + activity_id).css('marginTop', '0px');
      $('#activityCard_' + id + '_' + activity_id).css('width', '350px');
      $('#activityCard_' + id + '_' + activity_id).css('marginLeft', '0px');
      $('#activityCard_' + id + '_' + activity_id).css('boxShadow', '0px 0px 12px 5px rgba(0, 0, 0, 0.1)');
      $('#activityCard_' + id + '_' + activity_id).css('zIndex', '1');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.bottomContainer')
        .css('height', '90px');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_btn')
        .css('display', 'none');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_description')
        .css('opacity', '0');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_description')
        .css('height', '0px');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_description')
        .css('visibility', 'hidden');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_price')
        .css('opacity', '0');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_price')
        .css('height', '0px');
      $('#activityCard_' + id + '_' + activity_id)
        .find('.activity_card_price')
        .css('visibility', 'hidden');
    }
  }
}

function ActivityCard({ id, activity_id, data, shadow }) {
  useEffect(() => {
    if (shadow) {
      $('.activityCard_' + id + '_' + activity_id).css(
        'boxShadow',
        '0px 0px 12px 5px rgba(0,0,0,0.10)',
      );
    }
  }, []);

  const ref = useRef();
  const openTooltip = () => ref.current.open();

  function toDownloadAppIOS() {
    window.location.href = 'https://apps.apple.com/hk/app/vivablee/id1551773278';
  }
  function toDownloadAppAndroid() {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.vivableeraw';
  }

  const [thumbnailURL, setThumbnailURL] = useState();

  useEffect(()=> {
    getImageUrl();
  })

  const getImageUrl = async () => {
    const url = await Storage.get(data.imagesLarge[0]);
    setThumbnailURL(url);
  };

  const [loaded, setLoaded] = useState(false);

  // activity card content
  return (
    <div>
      <PopupModal Ref={ref} />
      <ActivityCardDiv className="activityCard" onClick={() => window.location.href = '/Activities/Details/' + data.id}>
        <ActivityCard2
          className={'activityCard_' + id + '_' + activity_id}
          id={'activityCard_' + id + '_' + activity_id}
          onMouseOver={() => activityCard_mouseOver(id, activity_id)}
          onMouseOut={() => activityCard_mouseOut(id, activity_id)}
        >
          <ActivityCardContainer>
            <ActivityCardImageCover
              style={{ backgroundColor: 'white', backgroundPosition: "center", backgroundImage: 'url(' + thumbnailURL + ')' }}
            />
          </ActivityCardContainer>
          <ActivityCardBottomContainer className="bottomContainer">
            <ActivityCardBtn className="activity_card_btn">
              <ActivityCardBtnIcon
                src={require('../images/general/play_white.png')}
              ></ActivityCardBtnIcon>
              <ActivityCardBtnText>立即報名</ActivityCardBtnText>
            </ActivityCardBtn>
            <ActivityCardBottomChillfulLogo
              src={require('../images/general/chillful_icon_original.png')}
            ></ActivityCardBottomChillfulLogo>
            <ActivityCardBottomText1>{data.categoryChi}</ActivityCardBottomText1>
            <ActivityCardBottomText2>{data.titleChi}</ActivityCardBottomText2>
            <ActivityCardBottomText3>
              {data.subcategoryChi} ● {data.duration/3600} 小時
          </ActivityCardBottomText3>
            <ActivityCardBottomDescription className="activity_card_description">
              {data.descriptionChi}
            </ActivityCardBottomDescription>
            <ActivityCardBottomPrice className="activity_card_price">
              港幣 {data.priceMin}+ / 每人
          </ActivityCardBottomPrice>
          </ActivityCardBottomContainer>
        </ActivityCard2>
      </ActivityCardDiv>
    </div>
  );
}

export default ActivityCard;

// define css class
const ActivityCardDiv = styled.div`
  position: relative;
`;
const ActivityCard2 = styled.div`
  border-radius: 30px;
  width: 350px;
  transition: all 0.5s;
  cursor: pointer;
  position: absolute;
`;
const ActivityCardContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(80% - 72px);
`;
const ActivityCardImageCover = styled.div`
  background-size: cover;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
const ActivityCardBottomContainer = styled.div`
  transition: height 0.5s;
  height: 90px;
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 12px 20px 0px 20px;
  color: black;
`;
const ActivityCardBtn = styled.div`
  background-color: #ff585d;
  padding: 8px 14px;
  width: 80px;
  border-radius: 50px;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 5px;
  display: none;
`;
const ActivityCardBtnIcon = styled.img`
  width: 12px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 4px;
`;
const ActivityCardBtnText = styled.span`
  margin-left: 5px;
  font-size: 14px;
  color: white;
`;
const ActivityCardBottomChillfulLogo = styled.img`
  height: 12px;
  margin-right: 2px;
`;
const ActivityCardBottomText1 = styled.span`
  font-size: 14px;
  margin-left: 3px;
  color: #ff585d;
  text-transform: capitalize;
  margin-top: 5px;
`;
const ActivityCardBottomText2 = styled.p`
  margin-top: 6px;
  font-size: 20px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
  font-weight: 600;
`;
const ActivityCardBottomText3 = styled.p`
  margin-top: 3px;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 0px;
  font-weight: 700;
`;
const ActivityCardBottomDescription = styled.p`
  margin-top: 5px;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  min-height: 3.3em;
  -webkit-box-orient: vertical;
  opacity: 0;
  height: 0px;
  visibility: hidden;
  color: #555;
`;
const ActivityCardBottomPrice = styled.p`
  margin-top: 5px;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  height: 0px;
  visibility: hidden;
`;