/* eslint-disable */

import React, {useState} from 'react';
import "./App.css";
import ScrollToTop from "./functions/ScrollToTop";
import Home from "./pages/Home";
import Activity from "./pages/Activity";
import Articles from "./pages/Articles";
import Article from "./pages/Article";
import Corporates from "./pages/Corporates";
import Meditation from "./pages/Meditation";
import AtoZ from "./pages/AtoZ";
import AtozPage from "./pages/AtozPage";
import ActivityDetails from "./pages/ActivityDetails";
import Dashboard from "./pages/Dashboard";
import ActivityUpdate from "./pages/ActivityUpdate";
import Downloads from "./pages/Downloads";
import Marketing from "./pages/Marketing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import WriteDataTemperature from "./pages/WriteDataTemperature";
import DownloadManatee from "./pages/DownloadManatee";
import Manatee14DayChallengeInfo from "./pages/Manatee14DayChallengeInfo";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Amplify, Hub } from "@aws-amplify/core";
import awsExports from "./aws-exports";
import { useEffect } from 'react';
import { DataStore } from 'aws-amplify';
import Lottie from 'react-lottie';
import $ from 'jquery'; // package to run jQuery
Amplify.configure(awsExports);

function App() {

  const [initDatabase, setInitDatabase] = useState(true);

  useEffect(()=>{
    const removeListener = Hub.listen("datastore", async (capsule) => {
      const {
        payload: { event, data },
      } = capsule;
      if (event === "ready") {
        setInitDatabase(false);
      }
    });
    // Start the DataStore, this kicks-off the sync process.
    DataStore.start();
    return () => {
      removeListener();
    };
  },[])

  const HOME = () => (
    <div>
      <Helmet>
        <title>Vivablee | 你的身心健康夥伴</title>
        <meta
          name="description"
          content="Vivablee 是一個心理健康服務的綜合平台。"
        />
        <meta name="keywords" content="休閒活動,冥想空間,免費試用,身心健康" />
      </Helmet>
      <Home />
    </div>
  );

  const ACTIVITIES = () => (
    <div>
      <Helmet>
        <title>特色活動</title>
        <meta name="description" content="Vivablee 提供一系列線上休閒活動體驗，讓你在家中亦能放鬆自己。" />
        <meta name="keywords" content="休閒活動,工作坊,Chill At Home,活動精選" />
      </Helmet>
      <Activity />
    </div>
  );

  const MEDITATION = () => (
    <div>
      <Helmet>
        <title>冥想空間</title>
        <meta name="description" content="透過靜觀旅程，體驗不同的冥想練習，並可以在建立恆常練習的習慣的同時，獲得不同的獎賞。" />
        <meta name="keywords" content="冥想旅程,冥想練習,靜觀體驗,冥想空間" />
      </Helmet>
      <Meditation />
    </div>
  );

  const CORPORATES = () => (
    <div>
      <Helmet>
        <title>企業服務</title>
        <meta name="description" content="Vivablee 為企業提供訂製的員工身心健康方案，由專人安排定期的專屬休閒活動體驗以及線上和線下的冥想體驗。在讓員工時刻保持身心健康並在日常工作大展所長的同時，施展企業社會責任！" />
        <meta name="keywords" content="企業服務,休閒活動,冥想體驗,精神健康" />
      </Helmet>
      <Corporates />
    </div>
  );

  const ARTICLES = () => (
    <div>
      <Helmet>
        <title>認識靜觀</title>
        <meta name="description" content="Vivablee 精選文章" />
        <meta name="keywords" content="認識靜觀,Vivablee,精選文章" />
      </Helmet>
      <Articles />
    </div>
  );

  const ATOZ = () => (
    <div>
      <Helmet>
        <title>Viva百科</title>
        <meta name="description" content="Vivablee AtoZ 百科" />
        <meta name="keywords" content="A to Z,百科,a to z,Vivablee" />
      </Helmet>
      <AtoZ />
    </div>
  );

  const ACTIVITYDETAILS = () => (
    <div>
      <Helmet>
        <title>活動詳情</title>
        <meta name="description" content="活動詳情" />
        <meta name="keywords" content="活動詳情" />
      </Helmet>
      <ActivityDetails />
    </div>
  );

  const MANATEE14DAYCHALLENGEINFO = () => (
    <div>
      <Helmet>
        <title>manatee 中大14天冥想挑戰規則及條款</title>
        {/* <meta name="description" content="透過靜觀旅程，體驗不同的冥想練習，並可以在建立恆常練習的習慣的同時，獲得不同的獎賞。" />
        <meta name="keywords" content="冥想旅程,冥想練習,靜觀體驗,冥想空間" /> */}
      </Helmet>
      <Manatee14DayChallengeInfo />
    </div>
  );


  const ARTICLE = () => (
    <Article />
  );

  const ATOZPAGE = () => (
    <AtozPage />
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('./images/general/loading.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div>
      <Router>
        <ScrollToTop />
        <div style={{ width: "100%" }}>
          <Route exact path="/app" component={Downloads} />
          <Route exact path="/manatee" component={DownloadManatee} />
          <Route exact path="/Marketing" component={Marketing} />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/TermsOfService" component={TermsOfService} />
          <Route exact path="/WriteData/Temperature/:data?" component={WriteDataTemperature} />
          <Route exact path="/manatee-14-day-challenge/info">
            <MANATEE14DAYCHALLENGEINFO />
          </Route>
        {initDatabase ?
          <div id={"loadingContainer"} style={{display: 'flex', alignContent: 'center', height:"calc(100 * var(--vh, 1vh))", alignItems: 'center', justifyContent: 'center', backgroundColor: '#fdf5ed'}}>
            <Lottie
              options={defaultOptions}
              isStopped={false}
              isPaused={false}
              style={{ maxWidth:300, maxHeight:300, height:'80vw', width:'80vw' }}
            />
          </div>
          :
          <>
          <Route exact path="/">
            <HOME />
            {/* <MetaChange pageName="vivablee" /> */}
          </Route>
          <Route exact path="/Activities">
            <ACTIVITIES />
            {/* <MetaChange pageName="activities" /> */}
          </Route>
          {/* <Route exact path="/Activities/Details" component={ActivityDetails} /> */}
          <Route exact path="/Activities/Details/:activityID">
            <ACTIVITYDETAILS />
          </Route>
          <Route exact path="/Articles">
            <ARTICLES />
          </Route>
          <Route exact path="/Articles/:p">
            <ARTICLE />
          </Route>
          <Route exact path="/Corporates">
            <CORPORATES />
          </Route>
          <Route exact path="/Meditation">
            <MEDITATION />
          </Route>
          {/* new add AtoZ */}
          <Route exact path="/AtoZ">
            <ATOZ />
          </Route>
          <Route exact path="/AtoZ/:page">
            <ATOZPAGE />
          </Route>
          {/* <Route exact path="/dynamic_sitemap.xml" component={SitemapGenerator} /> */}
          <Route exact path="/Business/Dashboard" component={Dashboard} />
          <Route exact path="/Business/ActivityUpdate" component={ActivityUpdate} />
          </>
          }
          
          {/* <Route exact path="/sitemap.xml" component={Sitemap} /> */}
        </div>
      </Router>
    </div>
  );
}
export default App;
